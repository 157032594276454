import React from 'react'
import { SiteDetailedStatus } from '../../../models/Site'
import { Col, Row } from 'react-bootstrap'

interface SiteStatusDetailsProps {
	detailedStatuses: SiteDetailedStatus[]
}

export const SiteStatusDetails = (props: SiteStatusDetailsProps) => {
	const statuses = props.detailedStatuses

	return (
		<Row>
			<Col>
				{statuses && statuses.length > 0
					? statuses.map((status, index) => {
							return <SiteStatusDetail detailedStatus={status} key={index} />
					  })
					: ''}
			</Col>
		</Row>
	)
}

interface StatusDetailProps {
	detailedStatus: SiteDetailedStatus
}

const SiteStatusDetail = (props: StatusDetailProps) => {
	const percentage = props.detailedStatus.percentage
	const statusType = props.detailedStatus.statusType

	const percentageToStatusColour = (statusPercentage: number | null) => {
		if (!statusPercentage) {
			return 'statusRed'
		}

		if (statusPercentage === 100) {
			return 'statusGreen'
		} else if (statusPercentage >= 75) {
			return 'statusOrange'
		} else {
			return 'statusRed'
		}
	}

	const classLabel = percentageToStatusColour(percentage)

	return (
		<>
			<span>
				{statusType}:<span> </span>
			</span>
			<span className={classLabel}>
				{percentage !== -1 ? percentage : 'N/A'}%<span> </span>
			</span>
		</>
	)
}
