import React from 'react'
import { Asset } from '../../../models/Asset'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { PageStatus } from '../../../types/PageStatus'
import { Reading, ReadingApiGroupType, ReadingApiWindow, ReadingApiWindowSelectOptions, ReadingResult, defaultReading } from '../../../models/Reading'
import { readingType } from '../../../constants/readingType'
import * as Request from '../../../utilities/request'
import Select from 'react-select'
import { Chart, ChartOptions, ScaleOptionsByType, CartesianScaleTypeRegistry, CartesianScaleOptions, CartesianTickOptions, ChartDataset } from 'chart.js'
import 'chartjs-adapter-luxon'
import htmlLegendPlugin from './htmlLegend'
import { Line } from 'react-chartjs-2'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { DateTime } from 'luxon'
import { useInterval } from '../../../utilities/useInterval'
import { Loading } from '../Loading/Loading'
import { colors } from '../../../constants/colors'
import { FormDate } from '../Form/Date'
import { calculateSuggestedMax, calculateSuggestedMin } from '../../../utilities/graphHelpers'

const getAllCharts = () => Object.keys(Chart.instances).map((key) => Chart.instances[key])

const getCommonChartOptions = (xAxisMin: number, xAxisMax: number, onMove: (context: { chart: Chart }) => void): ChartOptions<'line'> => ({
	animation: false,
	parsing: false,
	normalized: true,
	interaction: {
		mode: 'nearest',
		axis: 'x',
		intersect: false,
	},
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			caretPadding: 500,
			yAlign: 'bottom',
		},
		// Using HTML legend instead
		legend: {
			display: false,
		},
		zoom: {
			pan: {
				enabled: true,
				mode: 'x',
				onPan: onMove,
			},
			zoom: {
				wheel: {
					enabled: true,
				},
				pinch: {
					enabled: true,
				},
				mode: 'x',
				onZoom: onMove,
			},
			limits: {
				x: { min: xAxisMin, max: xAxisMax },
			},
		},
		decimation: {
			enabled: false, // TODO: Play around with decimation thresholds to make graph more performant with big data sets
			algorithm: 'lttb',
			samples: 400,
			threshold: 400,
		},
		datalabels: {
			display: false,
		},
	},
})

const getCommonXScaleOptions = (xAxisMin: number, xAxisMax: number, timezone: string) =>
	({
		type: 'time',
		time: {
			tooltipFormat: 'dd/MM/yyyy h:mm:ss a',
			displayFormats: {
				millisecond: 'h:mm:ss:SSS a',
				second: 'h:mm:ss a',
				minute: 'h:mm a',
				hour: 'dd/MM h a',
				day: 'dd/MM/yy',
				week: 'dd/MM/yy',
				month: 'MM/yyyy',
				quarter: 'MM/yyyy',
				year: 'yyyy',
			},
		},
		adapters: {
			date: {
				zone: timezone,
			},
		},
		title: {
			display: true,
			text: 'Timestamps',
		},
		min: xAxisMin,
		max: xAxisMax,
		ticks: {
			minRotation: 45,
			maxRotation: 45,
		},
	}) as unknown as ScaleOptionsByType<keyof CartesianScaleTypeRegistry>

const getCommonYScaleOptions = (): Partial<CartesianScaleOptions> => ({
	grid: {
		color: (ctx) => {
			if (ctx.tick.value === 0) {
				return 'rgba(0, 0, 0, 0.5)'
			}

			return 'rgba(0, 0, 0, 0.1)'
		},
	},
	ticks: {
		minRotation: 0,
		maxRotation: 0,
	} as CartesianTickOptions,
	// Ensures both charts use the same width for the y-axis labels to keep the rendered area the same
	afterFit(scale) {
		scale.width = 50
	},
})

interface AssetLiveTemperatureGraphProps {
	asset: Asset
}

const AssetLiveTemperatureGraph = (props: AssetLiveTemperatureGraphProps) => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [readingsTemp, setReadingsTemp] = React.useState<Reading[]>([])
	const [readingsFlow, setReadingsFlow] = React.useState<Reading[]>([])
	const [window, setWindow] = React.useState<ReadingApiWindow>(ReadingApiWindow.DAY)
	const [customWindow, setCustomWindow] = React.useState<string>(DateTime.now().toFormat('yyyy-MM-dd'))
	const [nowUtc, setNowUtc] = React.useState<DateTime>(DateTime.utc())
	const [flowReads, setFlowReads] = React.useState(false)

	const getData = async () => {
		setPageStatus('Loading')
		try {
			const windowString =
				window.value === ReadingApiWindow.CUSTOM.value
					? formatOutgoingDateTime({ dateTime: customWindow, format: 'DateString', timeZone: context.appState.currentSite?.site_Timezone })
					: window.value
			const [readingTempReq, readingFlowReq] = await Promise.all([
				Request.get<ReadingResult>(
					`reading?Asset_Id=${props.asset.asset_Id}&ReadingTypeGroup=${ReadingApiGroupType.TEMP}&Window=${windowString}`,
					context.appState.authState
				),
				Request.get<ReadingResult>(
					`reading?Asset_Id=${props.asset.asset_Id}&ReadingTypeGroup=${ReadingApiGroupType.FLOW}&Window=${windowString}`,
					context.appState.authState
				),
			])
			setReadingsTemp(readingTempReq.data.readings)
			setReadingsFlow(readingFlowReq.data.readings)
			setNowUtc(DateTime.utc())
			setPageStatus('Ready')
		} catch (e) {
			console.log(e)
			setPageStatus('Error')
		}
	}

	React.useEffect(() => {
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props, window, window == ReadingApiWindow.CUSTOM ? customWindow : undefined])

	useInterval(
		async () => {
			setPageStatus('Submitting')
			const [readingTempReq, readingFlowReq] = await Promise.all([
				Request.get<ReadingResult>(
					`reading?Asset_Id=${props.asset.asset_Id}&ReadingTypeGroup=${ReadingApiGroupType.TEMP}&Window=${window.value}&SinceUtcTs=${
						readingsTemp.length > 0 ? readingsTemp.at(-1)?.reading_Ts : nowUtc.toISO()
					}`,
					context.appState.authState
				),
				Request.get<ReadingResult>(
					`reading?Asset_Id=${props.asset.asset_Id}&ReadingTypeGroup=${ReadingApiGroupType.FLOW}&Window=${window.value}&SinceUtcTs=${
						readingsTemp.length > 0 ? readingsTemp.at(-1)?.reading_Ts : nowUtc.toISO()
					}`,
					context.appState.authState
				),
			])
			const newNowUtc = DateTime.utc()
			setReadingsTemp((readingsTemp) => [
				...readingsTemp.filter(
					(reading) =>
						parseInt(formatIncomingDateTime({ dateTime: reading.reading_Ts, format: 'Custom', customFormat: 'x' })) >
						newNowUtc.minus({ minutes: 5 }).toMillis()
				),
				...readingTempReq.data.readings,
			])
			setReadingsFlow((readingsFlow) => [
				...readingsFlow.filter(
					(reading) =>
						parseInt(formatIncomingDateTime({ dateTime: reading.reading_Ts, format: 'Custom', customFormat: 'x' })) >
						newNowUtc.minus({ minutes: 5 }).toMillis()
				),
				...readingFlowReq.data.readings,
			])
			setNowUtc(newNowUtc)
			setPageStatus('Ready')
		},
		pageStatus !== 'Loading' && window === ReadingApiWindow.FIVE_MINUTE ? 5000 : null
	)

	/*
		mapReading maps a Reading to a Chart.js data point
	*/
	const mapReading = (reading: Reading) => {
		return {
			x: parseInt(
				formatIncomingDateTime({
					dateTime: reading.reading_Ts,
					format: 'Custom',
					timeZone: context.appState.currentSite?.site_Timezone,
					customFormat: 'x',
				})
			),
			y: reading.reading_Data >= 0 ? reading.reading_Data : null, // TODO: Convert to fahrenheit based on site setting
		}
	}

	/*
		addGaps creates gaps between 2 data points if the difference between them is greater than 48 hours
	*/
	const addGaps = (accumulator: Reading[], currentValue: Reading, index: number, array: Reading[]) => {
		accumulator.push(currentValue)

		if (index < array.length - 1) {
			const currTs = DateTime.fromISO(currentValue.reading_Ts)
			const nextTs = DateTime.fromISO(array[index + 1].reading_Ts)

			if (nextTs.diff(currTs, 'hour').hours >= 48) {
				accumulator.push(
					defaultReading({
						...currentValue,
						reading_Ts: currentValue.reading_Ts,
						reading_Data: -1,
					})
				)
			}
		}

		return accumulator
	}

	const tempHotReadings = React.useMemo(
		() =>
			readingsTemp
				.filter((reading) => reading.readingType_Id === readingType['Temperature Reading Hot'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const tempColdReadings = React.useMemo(
		() =>
			readingsTemp
				.filter((reading) => reading.readingType_Id === readingType['Temperature Reading Cold'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const tempMixedReadings = React.useMemo(
		() =>
			readingsTemp
				.filter((reading) => reading.readingType_Id === readingType['Temperature Reading Mixed'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const flowHotReadings = React.useMemo(
		() =>
			readingsFlow
				.filter((reading) => reading.readingType_Id === readingType['Flow Reading Hot'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const flowColdReadings = React.useMemo(
		() =>
			readingsFlow
				.filter((reading) => reading.readingType_Id === readingType['Flow Reading Cold'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const flowMixedReadings = React.useMemo(
		() =>
			readingsFlow
				.filter((reading) => reading.readingType_Id === readingType['Flow Reading Mixed'].id)
				.reduce(addGaps, [])
				.map(mapReading),
		[readingsTemp]
	)

	const xAxisMin = React.useMemo(() => {
		let date: DateTime = nowUtc
		switch (window.value) {
			case 'month':
				date = nowUtc.minus({ months: 1 })
				break
			case 'week':
				date = nowUtc.minus({ weeks: 1 })
				break
			case 'day':
				date = nowUtc.minus({ hours: 24 })
				break
			case 'hour':
				date = nowUtc.minus({ hours: 1 })
				break
			case 'fiveMinute':
				date = nowUtc.minus({ minutes: 5 })
				break
			case 'custom':
				date = DateTime.fromFormat(customWindow, 'yyyy-MM-dd', { zone: context.appState.currentSite?.site_Timezone })
				break
			default:
				break
		}
		return parseInt(
			formatIncomingDateTime({
				dateTime: date.toISO() || '',
				format: 'Custom',
				timeZone: context.appState.currentSite?.site_Timezone,
				customFormat: 'x',
			})
		)
	}, [nowUtc])

	const xAxisMax = React.useMemo(() => {
		const date =
			window === ReadingApiWindow.CUSTOM
				? DateTime.fromFormat(customWindow, 'yyyy-MM-dd', { zone: context.appState.currentSite?.site_Timezone }).plus({ hours: 24 })
				: nowUtc
		return parseInt(
			formatIncomingDateTime({
				dateTime: date.toISO() || '',
				format: 'Custom',
				timeZone: context.appState.currentSite?.site_Timezone,
				customFormat: 'x',
			})
		)
	}, [nowUtc])

	const onChartMove = (context: { chart: Chart }) => {
		const min = context.chart.scales.x.min
		const max = context.chart.scales.x.max

		getAllCharts().forEach((chart) => {
			if (chart.id !== context.chart.id && chart.options.scales?.x) {
				chart.options.scales.x.min = min
				chart.options.scales.x.max = max
				chart.update()
			}
		})
	}

	const resetChartZoom = () => {
		getAllCharts().forEach((chart) => {
			if (chart.options.scales?.x) {
				chart.options.scales.x.min = xAxisMin
				chart.options.scales.x.max = xAxisMax
				chart.update()
			}
		})
	}

	const tempChartOptions: ChartOptions<'line'> = React.useMemo(() => {
		const commonOptions = getCommonChartOptions(xAxisMin, xAxisMax, onChartMove)
		const afterFitScale = flowReads ? 0 : 70
		return {
			...commonOptions,
			plugins: {
				...commonOptions.plugins,
				htmlLegend: {
					containerID: 'legend-container-temp',
				},
			},
			scales: {
				x: {
					...getCommonXScaleOptions(xAxisMin, xAxisMax, props.asset.site_Timezone),
					title: {
						display: false,
					},
					// This is the best way to hide the tick labels as it allows the graph to draw everything and
					// render divisions correctly, then just sets the height to 0 to 'hide' them. Otherwise issues
					// with the two charts having different tick divisions for the same time span which looks odd.
					afterFit: (scale) => {
						scale.height = afterFitScale
					},
				},
				y: {
					...getCommonYScaleOptions(),
					title: { display: true, text: 'Temperature (°C)' }, // TODO: Show fahrenheit based on site setting
					suggestedMin: calculateSuggestedMin(tempHotReadings.concat(tempColdReadings, tempMixedReadings)),
					suggestedMax: calculateSuggestedMax(tempHotReadings.concat(tempColdReadings, tempMixedReadings)),
				},
			},
		}
	}, [tempHotReadings, tempColdReadings, tempMixedReadings, flowHotReadings, flowColdReadings, flowMixedReadings, xAxisMin, xAxisMax, flowReads])

	const flowChartOptions: ChartOptions<'line'> = React.useMemo(() => {
		const commonOptions = getCommonChartOptions(xAxisMin, xAxisMax, onChartMove)
		return {
			...commonOptions,
			plugins: {
				...commonOptions.plugins,
				htmlLegend: {
					containerID: 'legend-container-flow',
				},
			},
			scales: {
				x: {
					...getCommonXScaleOptions(xAxisMin, xAxisMax, props.asset.site_Timezone),
				},
				y: {
					...getCommonYScaleOptions(),
					title: { display: true, text: 'Flow (Hz)' },
					suggestedMin: calculateSuggestedMin(flowHotReadings.concat(flowColdReadings, flowMixedReadings)),
					suggestedMax: calculateSuggestedMax(flowHotReadings.concat(flowColdReadings, flowMixedReadings)),
				},
			},
		}
	}, [tempHotReadings, tempColdReadings, tempMixedReadings, flowHotReadings, flowColdReadings, flowMixedReadings, xAxisMin, xAxisMax])

	const temperatureDataSet: ChartDataset<
		'line',
		{
			x: number
			y: number | null
		}[]
	>[] = React.useMemo(() => {
		const dataSet: ChartDataset<
			'line',
			{
				x: number
				y: number | null
			}[]
		>[] = []

		if (tempHotReadings.length > 0) {
			dataSet.push({
				label: 'Hot',
				borderColor: colors.colorStatusRed,
				backgroundColor: colors.colorStatusRed,
				data: tempHotReadings,
				indexAxis: 'x',
				parsing: false,
			})
		}
		if (tempColdReadings.length > 0) {
			dataSet.push({
				label: 'Cold',
				borderColor: colors.colorStatusBlue,
				backgroundColor: colors.colorStatusBlue,
				data: tempColdReadings,
				indexAxis: 'x',
				parsing: false,
			})
		}
		if (tempMixedReadings.length > 0) {
			dataSet.push({
				label: 'Mixed',
				borderColor: colors.colorStatusYellow,
				backgroundColor: colors.colorStatusYellow,
				data: tempMixedReadings,
				indexAxis: 'x',
				parsing: false,
			})
		}

		if (tempHotReadings.length === 0 && tempColdReadings.length === 0 && tempMixedReadings.length === 0) {
			dataSet.push({
				label: 'No data to display',
				data: [],
				indexAxis: 'x',
				parsing: false,
			})
		}

		return dataSet
	}, [tempHotReadings, tempColdReadings, tempMixedReadings])

	const flowDataSet: ChartDataset<
		'line',
		{
			x: number
			y: number | null
		}[]
	>[] = React.useMemo(() => {
		const dataSet: ChartDataset<
			'line',
			{
				x: number
				y: number | null
			}[]
		>[] = []

		if (tempHotReadings.length > 0) {
			dataSet.push({
				label: 'Hot',
				borderColor: colors.colorStatusRed,
				backgroundColor: colors.colorStatusRed,
				data: flowHotReadings,
				indexAxis: 'x',
				parsing: false,
				stepped: true,
			})
		}
		if (tempColdReadings.length > 0) {
			dataSet.push({
				label: 'Cold',
				borderColor: colors.colorStatusBlue,
				backgroundColor: colors.colorStatusBlue,
				data: flowColdReadings,
				indexAxis: 'x',
				parsing: false,
				stepped: true,
			})
		}
		if (flowMixedReadings.length > 0) {
			dataSet.push({
				label: 'Mixed',
				borderColor: colors.colorStatusYellow,
				backgroundColor: colors.colorStatusYellow,
				data: flowMixedReadings,
				indexAxis: 'x',
				parsing: false,
				stepped: true,
			})
		}

		return dataSet
	}, [flowHotReadings, flowColdReadings, flowMixedReadings])

	React.useEffect(() => {
		if (flowDataSet.length !== 0) {
			setFlowReads(true)
		} else {
			setFlowReads(false)
		}
	}, [flowDataSet])

	return (
		<Card>
			{pageStatus === 'Loading' ? (
				<Loading show />
			) : (
				<>
					{/* TEMP CHART */}
					<Row>
						<Col sm={10} style={styles.graph}>
							<Line
								data={{
									datasets: temperatureDataSet,
								}}
								options={tempChartOptions}
								plugins={[htmlLegendPlugin]}
							/>
						</Col>

						<Col sm={2} className="d-flex flex-column">
							<Row>
								<Col>
									<Select value={window} onChange={(e) => setWindow(e as ReadingApiWindow)} options={ReadingApiWindowSelectOptions} />
									{window === ReadingApiWindow.CUSTOM && (
										<>
											<FormDate
												name="CustomWindow"
												label={''}
												value={customWindow}
												onChange={(e) => setCustomWindow(e.target.value)}
												className="w-100 mt-3"
											/>
										</>
									)}
									<Button variant="secondary" className="w-100 mt-3" onClick={resetChartZoom}>
										Reset Zoom
									</Button>
								</Col>
							</Row>

							{window === ReadingApiWindow.FIVE_MINUTE && (
								<Row className="mt-3">
									<Col className="center-flex-col">
										<span>Polling for New Readings</span>
									</Col>
								</Row>
							)}
							<Row className="flex-fill d-flex">
								<Col className="d-flex align-items-center">
									{/* This has a legend inserted by htmlLegendPlugin using the id for the chart */}
									<div id="legend-container-temp"></div>
								</Col>
							</Row>
						</Col>
					</Row>

					{/* FLOW CHART */}
					{flowDataSet.length == 0 ? null : (
						<Row>
							<Col sm={10} style={styles.graph}>
								<Line
									data={{
										datasets: flowDataSet,
									}}
									options={flowChartOptions}
									plugins={[htmlLegendPlugin]}
								/>
							</Col>
							<Col sm={2} style={{ display: 'flex', alignItems: 'center' }}>
								{/* This has a legend inserted by htmlLegendPlugin using the id for the chart */}
								<div id="legend-container-flow"></div>
							</Col>
						</Row>
					)}
				</>
			)}
		</Card>
	)
}

const styles = {
	graph: {
		height: '400px',
	},
} satisfies Record<string, React.CSSProperties>

export default AssetLiveTemperatureGraph
