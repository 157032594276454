import React from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Line } from '../../components/UI/Line/Line'
import { Back } from '../../components/UI/Back/Back'
import { Contract, ContractResult } from '../../models/Contract'
import { PageStatus } from '../../types/PageStatus'
import PermissionsCheck from '../../components/Permissions/PermissionsCheck'
import { PermissionModelObject, PermissionModelAction, PermissionModelContext } from '../../utilities/permissions/permission.d'
import { Button } from '../../components/UI/Button/Button'
import { FormText } from '../../components/UI/Form/Text'
import { ConfirmModal } from '../../components/UI/ConfirmModal/ConfirmModal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Listing, ListingColumn } from '../../components/UI/Listing/Listing'
import { FileUpload } from '../../components/UI/FileUpload/FileUpload'
import { ContractModule, ContractModuleResult, defaultContractModule } from '../../models/ContractModule'
import { PaymentAgreement, PaymentAgreementResult, defaultPaymentAgreement } from '../../models/PaymentAgreement'
import { hasPermission } from '../../utilities/permissions/permission'
import ellipsis from '../../images/icons/options.svg'
import { PaymentAgreementModal } from '../../components/UI/ContractDetails/PaymentAgreementModal'
import { defaultCreated, defaultModified } from '../../models/History'
import { ContractModuleModal } from '../../components/UI/ContractDetails/ContractModuleModal'
import { ModuleDropdownValues } from '../../constants/module'
import { DateTime } from 'luxon'
import { ABNValidChecksum, ABNValidForm, ABNFormat } from '../../utilities/abn'
import { PaymentAgreementMigrationModal } from '../../components/UI/ContractDetails/PaymentAgreementMigrationModal'
import { formatIncomingDateTime } from '../../utilities/formatDate'
import { FormDate } from '../../components/UI/Form/Date'

const contractValidationSchema = Yup.object().shape({
	contract_BuyerName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_BuyerABN: Yup.string()
		.notRequired()
		.test('abn-form', 'An ABN must be 11 digits', (value) => {
			return value ? ABNValidForm(value) : true
		})
		.test('abn-valid', 'This is not a valid ABN', (value) => {
			return value ? ABNValidChecksum(value) : true
		}),
	contract_VisualNumber: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	contract_VisualShipTo: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	contract_CustomerAccount: Yup.string().notRequired().max(10, 'Cannot be longer than ${max} characters.'),
	contract_CustomerCostCentre: Yup.string().notRequired().max(10, 'Cannot be longer than ${max} characters.'),
	contract_NswHealthPrefix: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	site_Id: Yup.string().required('A Site must be selected.'),
	contract_StartDate: Yup.string()
		.notRequired()
		.test('validStartDate', 'Start Date cannot be later than End Date', (value, context) => {
			return value && context.parent.contract_EndDate ? value < context.parent.contract_EndDate : true
		}),
	contract_EndDate: Yup.string()
		.notRequired()
		.test('validEndDate', 'End Date cannot be earlier than Start Date', (value, context) => {
			return value && context.parent.contract_StartDate ? value > context.parent.contract_StartDate : true
		}),
	contract_PaymentAgreements: Yup.array().of(
		Yup.object().shape({
			paymentAgreement_OrderNumber: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
			paymentAgreement_LineNumber: Yup.number().integer('Quantity must be a whole number'),
			paymentAgreement_StartDate: Yup.string()
				.notRequired()
				.test('validPAStartDate', 'Start Date cannot be later than End Date', (value, context) => {
					return value && context.parent.contract_EndDate ? value < context.parent.contract_EndDate : true
				}),
			paymentAgreement_EndDate: Yup.string()
				.notRequired()
				.test('validPAEndDate', 'End Date cannot be earlier than Start Date', (value, context) => {
					return value && context.parent.contract_StartDate ? value > context.parent.contract_StartDate : true
				}),
			paymentAgreement_AssetCount: Yup.number().integer('Quantity must be a whole number'),
			paymentAgreement_Note: Yup.string().notRequired().max(500, 'Cannot be longer than ${max} characters.'),
		})
	),
	contract_FirstName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_LastName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_Email: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.').email('Not a valid email.'),
	contract_Phone: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_Note: Yup.string().notRequired().max(1000, 'Cannot be longer than ${max} characters.'),
})

const ContractDetails = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [contract, setContract] = React.useState<Contract | null>(null)
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const [paymentAgreementModal, setPaymentAgreementModal] = React.useState<PaymentAgreement | null>(null)
	const [paymentAgreementModalEdit, setPaymentAgreementModalEdit] = React.useState<boolean>(false)

	const [paymentAgreementMigrationModal, setPaymentAgreementMigrationModal] = React.useState<PaymentAgreement | null>(null)

	const [contractModuleModal, setContractModuleModal] = React.useState<ContractModule | null>(null)
	const [contractModuleModalEdit, setContractModuleModalEdit] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getData = async () => {
			const [contractReq] = await Promise.all([Request.get<ContractResult>(`contract?Id=${id}`, context.appState.authState)])
			if (contractReq.data.contracts.length > 0) {
				setContract(contractReq.data.contracts[0])
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	const handleSave = async (contract: Contract) => {
		setPageStatus('Submitting')
		let stopSubmit = false

		// submit the Contract
		await Request.handleRequest(
			() =>
				Request.put<ContractResult>(
					'contract',
					{ ...contract, contract_BuyerABN: contract.contract_BuyerABN?.replace(/\s/g, '') }, // Trim all spaces from the ABN
					context.appState.authState
				),
			{
				failedFunction: () => {
					stopSubmit = true
					setPageStatus('Error')
				},
			}
		)

		// submit the Contract Modules
		if (!stopSubmit) {
			await Promise.all(
				contract.contract_Modules.map(async (contract_Module) => {
					await Request.handleRequest(() => Request.put<ContractModuleResult>('contractModule', contract_Module, context.appState.authState), {
						failedFunction: () => {
							stopSubmit = true
							setPageStatus('Error')
						},
					})
				})
			)
		}

		// submit the Payment Agreements
		if (!stopSubmit) {
			await Promise.all(
				contract.contract_PaymentAgreements.map(async (paymentAgreement) => {
					await Request.handleRequest(() => Request.put<PaymentAgreementResult>('paymentAgreement', paymentAgreement, context.appState.authState), {
						failedFunction: () => {
							stopSubmit = true
							setPageStatus('Error')
						},
					})
				})
			)
		}

		if (stopSubmit) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Error editing the contract`,
					dismissible: true,
					timeout: 5000,
				},
			})
		} else {
			setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successfully edited the contract`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}

		navigate(`/contract/${contract.contract_Id}`)
	}

	const handleDelete = async () => {
		await Request.handleRequest(() => Request.del<ContractResult>(`contract?Id=${contract?.contract_Id}`, context.appState.authState), {
			successFunction: () => {
				navigate('/contracts')
			},
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'contract',
		})
	}

	const handlePaymentAgreementDelete = async (paymentAgreement_Id: string) => {
		await Request.handleRequest(() => Request.del<PaymentAgreementResult>(`paymentAgreement?Id=${paymentAgreement_Id}`, context.appState.authState), {
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'Payment Agreement',
		})
	}

	const handleContractModuleDelete = async (contractModule_Id: string) => {
		await Request.handleRequest(() => Request.del<ContractModuleResult>(`contractModule?Id=${contractModule_Id}`, context.appState.authState), {
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'Contract Module',
		})
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<ConfirmModal
				show={showModal}
				setShow={setShowModal}
				title={'Confirm delete'}
				body={'Are you sure you want to delete this asset? All of its documents will also be deleted.'}
				onConfirm={handleDelete}
			/>
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{contract && (
				<Formik initialValues={contract} validationSchema={contractValidationSchema} onSubmit={handleSave} enableReinitialize>
					{({ handleSubmit, errors, values, setValues, handleChange }) => (
						<>
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<div className="page-header-primary">
										<h1 className="page-header-primary">{contract.site?.site_Name || contract.siteGroup?.siteGroup_Name}</h1>
									</div>
									<span className="page-header-divider">|</span>
									<h2 className="page-header-secondary">contract</h2>
								</Col>

								{pageStatus == 'Editing' ? (
									<PermissionsCheck
										object={PermissionModelObject.Contract}
										action={PermissionModelAction.DELETE}
										context={PermissionModelContext.None}
									>
										<Col sm={'auto'}>
											<Button variant={'danger'} onClick={() => setShowModal(true)}>
												Delete
											</Button>
										</Col>
									</PermissionsCheck>
								) : (
									<PermissionsCheck
										object={PermissionModelObject.Contract}
										action={PermissionModelAction.POST}
										context={PermissionModelContext.None}
									>
										<Col sm={'auto'}>
											<Button onClick={() => setPageStatus('Editing')}>Edit</Button>
										</Col>
									</PermissionsCheck>
								)}
							</Row>
							<Row style={styles.row}>
								<Line />
							</Row>
							<Row style={styles.row}>
								<h2 className="page-header-secondary">Contract Configuration</h2>
							</Row>

							<Card className="site-card">
								<Row className="generic-card-list-heading-row">
									<Col>
										<span className="dashboard-card-titles">Customer Details</span>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_Number'}
											label={'Enware Statement of Work'}
											value={`${values.contract_VisualNumber || ''}_${values.contract_VisualShipTo || ''}_${values.contract_Number}`}
											feedback={errors?.contract_Number}
											isInvalid={!!errors?.contract_Number}
											disabled
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_VisualNumber'}
											label={'Visual Customer Number'}
											value={values.contract_VisualNumber || ''}
											feedback={errors?.contract_VisualNumber}
											isInvalid={!!errors?.contract_VisualNumber}
											disabled
										/>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_BuyerABN'}
											label={'ABN'}
											value={pageStatus != 'Editing' ? ABNFormat(values.contract_BuyerABN) : values.contract_BuyerABN || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_BuyerABN}
											isInvalid={!!errors?.contract_BuyerABN}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_BuyerName'}
											label={'Entity Name'}
											value={values.contract_BuyerName || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_BuyerName}
											isInvalid={!!errors?.contract_BuyerName}
										/>
									</Col>
								</Row>

								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_VisualShipTo'}
											label={'Visual Ship-To'}
											value={values.contract_VisualShipTo || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_VisualShipTo}
											isInvalid={!!errors?.contract_VisualShipTo}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_CustomerAccount'}
											label={'Customer Account'}
											value={values.contract_CustomerAccount || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_CustomerAccount}
											isInvalid={!!errors?.contract_CustomerAccount}
										/>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_CustomerCostCentre'}
											label={'Customer Cost Center'}
											value={values.contract_CustomerCostCentre || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_CustomerCostCentre}
											isInvalid={!!errors?.contract_CustomerCostCentre}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_NswHealthPrefix'}
											label={'NSW Health Prefix'}
											value={values.contract_NswHealthPrefix || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_NswHealthPrefix}
											isInvalid={!!errors?.contract_NswHealthPrefix}
										/>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormDate
											name={`contract_StartDate`}
											label={'Start Date'}
											value={
												formatIncomingDateTime({
													dateTime: DateTime.fromISO(values.contract_StartDate || '').toISO({ includeOffset: false }) || '',
													format: 'FileDate',
												}) || undefined
											}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_StartDate}
											isInvalid={!!errors?.contract_StartDate}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormDate
											name={`contract_EndDate`}
											label={'End Date'}
											value={
												formatIncomingDateTime({
													dateTime: DateTime.fromISO(values.contract_EndDate || '').toISO({ includeOffset: false }) || '',
													format: 'FileDate',
												}) || undefined
											}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_EndDate}
											isInvalid={!!errors?.contract_EndDate}
										/>
									</Col>
								</Row>
							</Card>

							<Card className="site-card">
								<Row className="generic-card-list-heading-row">
									<Col>
										<span className="dashboard-card-titles">Contact Details</span>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_FirstName'}
											label={'First Name'}
											value={values.contract_FirstName || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_FirstName}
											isInvalid={!!errors?.contract_FirstName}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_LastName'}
											label={'Last Name'}
											value={values.contract_LastName || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_LastName}
											isInvalid={!!errors?.contract_LastName}
										/>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_Email'}
											label={'Email'}
											value={values.contract_Email || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_Email}
											isInvalid={!!errors?.contract_Email}
										/>
									</Col>
									<Col sm={6} className="edit-asset-field">
										<FormText
											name={'contract_Phone'}
											label={'Phone'}
											value={values.contract_Phone || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_Phone}
											isInvalid={!!errors?.contract_Phone}
										/>
									</Col>
								</Row>
								<Row style={styles.row}>
									<Col className="edit-asset-field">
										<FormText
											name={'contract_Note'}
											label={'Notes'}
											as={'textarea'}
											value={values.contract_Note || ''}
											onChange={handleChange}
											disabled={pageStatus != 'Editing'}
											feedback={errors?.contract_Note}
											isInvalid={!!errors?.contract_Note}
										/>
									</Col>
								</Row>
							</Card>

							<Row>
								<Col sm="auto" className="center-flex">
									<h2 className="mb-0">Payment Agreements</h2>
								</Col>
								<Col />
								<Col sm="auto" className="center-flex">
									{pageStatus == 'Editing' && (
										<Button
											onClick={() => {
												setPaymentAgreementModalEdit(false)
												setPaymentAgreementModal(
													defaultPaymentAgreement({
														contract_Id: values.contract_Id,
														created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
														modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
													})
												)
											}}
										>
											Add Payment Agreement
										</Button>
									)}
								</Col>
							</Row>
							<Listing
								name="Payment Agreement"
								namePlural="Payment Agreement"
								list={values.contract_PaymentAgreements || []}
								getIDFunc={(item) => item.paymentAgreement_Id}
								selectedActions={[]}
								headerButtons={[]}
								columns={(
									[
										{
											value: (item) => item.paymentAgreement_OrderNumber || '',
											render: (item) => <>{item.paymentAgreement_OrderNumber}</>,
											showHeader: true,
											headerText: 'Agreement ID',
											sortColumnName: 'paymentAgreement_OrderNumber',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_OrderNumber',
											},
										},
										{
											value: (item) => item.paymentAgreement_LineNumber.toString(),
											render: (item) => <>{item.paymentAgreement_LineNumber}</>,
											showHeader: true,
											headerText: 'Agreement Item',
											sortColumnName: 'paymentAgreement_LineNumber',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_LineNumber',
											},
										},
										{
											value: (item) => item.paymentAgreement_StartDate || '',
											render: (item) => (
												<>
													{formatIncomingDateTime({
														dateTime: DateTime.fromISO(item.paymentAgreement_StartDate || '').toISO({ includeOffset: false }) || '',
														format: 'Date',
													}) || undefined}
												</>
											),
											showHeader: true,
											headerText: 'Start Date',
											sortColumnName: 'paymentAgreement_StartDate',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_StartDate',
											},
										},
										{
											value: (item) => item.paymentAgreement_EndDate || '',
											render: (item) => (
												<>
													{formatIncomingDateTime({
														dateTime: DateTime.fromISO(item.paymentAgreement_EndDate || '').toISO({ includeOffset: false }) || '',
														format: 'Date',
													}) || undefined}
												</>
											),
											showHeader: true,
											headerText: 'End Date',
											sortColumnName: 'paymentAgreement_EndDate',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_EndDate',
											},
										},
										{
											value: (item) => item.paymentAgreement_AssetCount.toString(),
											render: (item) => <>{item.paymentAgreement_AssetCount}</>,
											showHeader: true,
											headerText: 'Agreement Qty',
											sortColumnName: 'paymentAgreement_AssetCount',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_AssetCount',
											},
										},
										{
											value: (item) => item.paymentAgreement_AssetCountAssigned.toString(),
											render: (item) => <>{item.paymentAgreement_AssetCountAssigned}</>,
											showHeader: true,
											headerText: 'Assigned Qty',
											sortColumnName: 'paymentAgreement_AssetCountAssigned',
											filterType: 'string',
											filterOptions: {
												columnName: 'paymentAgreement_AssetCountAssigned',
											},
										},
									] as ListingColumn<PaymentAgreement>[]
								).concat(
									pageStatus != 'Editing' ||
										!hasPermission(PermissionModelObject.Contract, PermissionModelAction.PUT, context.appState, PermissionModelContext.None)
										? []
										: [
												{
													value: (item) => item.paymentAgreement_Id,
													render: (item) => (
														<>
															<Dropdown drop="start">
																<Dropdown.Toggle
																	as="img"
																	src={ellipsis}
																	alt="Asset options"
																	className="site-card-dropdown-toggle"
																/>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() => {
																			setPaymentAgreementModalEdit(true)
																			setPaymentAgreementModal(item)
																		}}
																	>
																		Edit
																	</Dropdown.Item>
																	<Dropdown.Item
																		onClick={() => {
																			setPaymentAgreementMigrationModal(item)
																		}}
																	>
																		Migrate
																	</Dropdown.Item>
																	<Dropdown.Item
																		onClick={() => {
																			handlePaymentAgreementDelete(item.paymentAgreement_Id)
																			const newAgreements = values.contract_PaymentAgreements.filter(
																				(p) => p.paymentAgreement_Id != item.paymentAgreement_Id
																			)
																			setValues({ ...values, contract_PaymentAgreements: newAgreements })
																		}}
																	>
																		Delete
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</>
													),
													showHeader: true,
													headerText: 'Action',
													centerColumn: true,
												},
										  ]
								)}
								defaultSort={{ column: 'paymentAgreement_EndDate', order: 'DSC' }}
								isLoading={pageStatus === 'Loading'}
							/>

							<Row>
								<Col sm="auto" className="center-flex">
									<h2 className="mb-0">Modules</h2>
								</Col>
								<Col />
								<Col sm="auto" className="center-flex">
									{pageStatus === 'Editing' && (
										<Button
											onClick={() => {
												setContractModuleModalEdit(false)
												setContractModuleModal(
													defaultContractModule({
														contract_Id: values.contract_Id,
														created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
														modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
													})
												)
											}}
										>
											Add Contract Module
										</Button>
									)}
								</Col>
							</Row>
							<Listing
								name="Module"
								namePlural="Modules"
								list={values.contract_Modules || []}
								getIDFunc={(item) => item.contractModule_Id}
								selectedActions={[]}
								headerButtons={[]}
								columns={(
									[
										{
											value: (item) => item.module_ProductCode || '',
											render: (item) => <>{item.module_ProductCode}</>,
											showHeader: true,
											headerText: 'Product Code:',
											sortColumnName: 'module_ProductCode',
											filterType: 'string',
											filterOptions: {
												columnName: 'module_ProductCode',
											},
										},
										{
											value: (item) => item.module_Name || '',
											render: (item) => <>{item.module_Name}</>,
											showHeader: true,
											headerText: 'Name:',
											sortColumnName: 'module_Name',
											filterType: 'string',
											filterOptions: {
												columnName: 'module_Name',
											},
										},
										{
											value: (item) => (item.contractModule_AssetRate ? item.contractModule_AssetRate.toString() : ''),
											render: (item) => <>{item.contractModule_AssetRate}</>,
											showHeader: true,
											headerText: 'Price Per Module',
											sortColumnName: 'contractModule_AssetRate',
											filterType: 'string',
											filterOptions: {
												columnName: 'contractModule_AssetRate',
											},
										},
									] as ListingColumn<ContractModule>[]
								).concat(
									pageStatus != 'Editing' ||
										!hasPermission(PermissionModelObject.Contract, PermissionModelAction.PUT, context.appState, PermissionModelContext.None)
										? []
										: [
												{
													value: (item) => item.contractModule_Id,
													render: (item) => (
														<>
															<Dropdown>
																<Dropdown.Toggle
																	as="img"
																	src={ellipsis}
																	alt="Asset options"
																	className="site-card-dropdown-toggle"
																/>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() => {
																			setContractModuleModalEdit(true)
																			setContractModuleModal(item)
																		}}
																	>
																		Edit
																	</Dropdown.Item>
																	<Dropdown.Item
																		onClick={() => {
																			handleContractModuleDelete(item.contractModule_Id)
																			const newModules = values.contract_Modules.filter(
																				(m) => m.contractModule_Id != item.contractModule_Id
																			)
																			setValues({ ...values, contract_Modules: newModules })
																		}}
																	>
																		Delete
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</>
													),
													showHeader: true,
													headerText: 'Action',
													centerColumn: true,
												},
										  ]
								)}
								defaultSort={{ column: 'contractModule_AssetRate', order: 'ASC' }}
								isLoading={pageStatus === 'Loading'}
							/>

							<Card className="site-card" style={styles.card}>
								<Row className="site-card-list-heading-row">
									<span className="dashboard-card-titles">Files</span>
								</Row>
								<Row style={uploadStyles.row}>
									<Col>
										<FileUpload contract_Id={values.contract_Id} readOnly={false} hideTitle listView styles={uploadStyles} />
									</Col>
								</Row>
							</Card>

							{pageStatus == 'Editing' && (
								<PermissionsCheck
									object={PermissionModelObject.Contract}
									action={PermissionModelAction.PUT}
									context={PermissionModelContext.None}
								>
									<Row style={styles.row}>
										<Col sm={'1'}>
											<Button
												style={styles.button}
												variant={'secondary'}
												onClick={() => {
													setValues(contract)
													setPageStatus('Ready')
												}}
											>
												Cancel
											</Button>
										</Col>
										<Col sm={'1'}>
											<Button style={styles.button} onClick={() => handleSubmit()}>
												Save
											</Button>
										</Col>
									</Row>
								</PermissionsCheck>
							)}

							<PaymentAgreementModal
								paymentAgreement={paymentAgreementModal}
								setPaymentAgreement={setPaymentAgreementModal}
								editMode={paymentAgreementModalEdit}
								contract={values}
								setContract={setValues}
							/>

							<PaymentAgreementMigrationModal
								paymentAgreement={paymentAgreementMigrationModal}
								setPaymentAgreement={setPaymentAgreementMigrationModal}
								contract={values}
								setContract={setValues}
							/>

							<ContractModuleModal
								contractModule={contractModuleModal}
								setContractModule={setContractModuleModal}
								editMode={contractModuleModalEdit}
								modalModulesFiltered={ModuleDropdownValues.filter(
									(md) => !values.contract_Modules.some((m) => m.module_Id === (md.value as string))
								)}
								contract={values}
								setContract={setValues}
							/>
						</>
					)}
				</Formik>
			)}
		</div>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		padding: '40px',
	},
	row: {
		marginBottom: '40px',
	},
	spacedRow: {
		justifyContent: 'space-between',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	card: {
		marginTop: '20px',
	},
}

const uploadStyles: { [key: string]: React.CSSProperties } = {
	outerContainerStyle: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	uploadedContainerStyle: {
		boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.06)',
		borderRadius: '8px',
		backgroundColor: 'white',
		border: '1px solid rgba(0, 0, 0, 0.5)',
		height: '200px',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '10px 20px 20px 0px',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		flexBasis: '48%',
	},
	uploaderContainerStyle: {
		flexBasis: '48%',
		marginTop: '0px',
		height: '200px',
	},
	row: {
		marginTop: '20px',
	},
}

export { ContractDetails }
