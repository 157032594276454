import * as msal from '@azure/msal-browser'

const apiEndpoint_Local = 'localhost:7071/api'
const reportApiEndpoint_Local = 'localhost:7074/api'

const AAD_B2C_Name = 'enwareauditcommissioning'
// const B2C_signUpSignIn = 'B2C_1_signupSignin'
const B2C_signIn = 'B2C_1_signin'
const B2C_1_passwordReset = 'B2C_1_passwordReset'

const b2cPolicies = {
	names: {
		signIn: `${B2C_signIn}`,
		forgotPassword: `${B2C_1_passwordReset}`,
	},
	authorities: {
		signIn: {
			authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_signIn}`,
		},
		// signUpSignIn: {
		// 	authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_signUpSignIn}`,
		// },
		forgotPassword: {
			authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_1_passwordReset}`,
		},
	},
	authorityDomain: `${AAD_B2C_Name}.b2clogin.com`,
}

const msalConfig_Local = {
	auth: {
		clientId: '2466eb22-d1d5-4bea-bf39-b3c76b61e4b5', // This is the ONLY mandatory field; everything else is optional.
		clientSecret: 'u3Q8Q~BfKSg6DAAHnBdTvawSh2E.Rqrmt1kpNc6V',
		authority: b2cPolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
		knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
		redirectUri: 'http://localhost:3000/home', // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
	},
	scopes: ['https://enwareauditcommissioning.onmicrosoft.com/2466eb22-d1d5-4bea-bf39-b3c76b61e4b5/api-CRUD'],
	system: {
		loggerOptions: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			loggerCallback: (level: any, message: any, containsPii: any) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case msal.LogLevel.Error:
						console.log('Error', message)
						return
					case msal.LogLevel.Info:
						console.log('Info', message)
						return
					case msal.LogLevel.Verbose:
						console.log('Verbose', message)
						return
					case msal.LogLevel.Warning:
						console.log('Warning', message)
						return
				}
			},
		},
	},
}

const msalInstance_Local = new msal.PublicClientApplication(msalConfig_Local)

export { msalInstance_Local, msalConfig_Local, apiEndpoint_Local, reportApiEndpoint_Local }
