import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Form, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Listing, ListingColumn } from '../Listing/Listing'
import { AssetFlow, Flow, FlowResult } from '../../../models/Report'
import { Link } from 'react-router-dom'
import { formatOutgoingDateTime } from '../../../utilities/formatDate'
import { ReportApiFlowWindowType, ReportApiFlowWindowTypeSelectOptions, ReportApiType } from '../../../constants/report'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import ExportDropdown, { CsvData } from './ExportDropdown'
import { Messages, useMessageReducer } from '../Messages/Messages'
import { Button } from '../Button/Button'
import { secondsToReadable } from '../../../utilities/strings'
import './Checkbox.css'
import CalendarHeatmap from 'react-calendar-heatmap'
import { Tooltip } from 'react-tooltip'
import './CalendarHeatmap.css'

const getCsvFileHeaders = (flowWindowType: ReportApiFlowWindowType, reportDate: DateTime) => [
	{ key: 'asset_Name', columnName: 'TMV ID' },
	{ key: 'asset_Location', columnName: 'Location' },
	{ key: 'daysBelowThreshold', columnName: 'Days Below Threshold' },
	...Array.from(
		{ length: flowWindowType === ReportApiFlowWindowType.MONTH ? reportDate.daysInMonth || 30 : flowWindowType === ReportApiFlowWindowType.WEEK ? 7 : 1 },
		(_, i) => {
			const label = `${reportDate.plus({ days: i }).toFormat('dd/MM')}`
			return {
				key: label,
				columnName: label,
			}
		}
	),
	{ key: 'totalRuntime', columnName: 'Total Runtime' },
]

interface FlowListingProps {
	reportTypeSelect: JSX.Element
	usageContext: 'site' | 'siteGroup'
}

const FlowListing = (props: FlowListingProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [flow, setFlow] = React.useState<Flow>()
	const [reportDate, setReportDate] = React.useState(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
	const [flowWindowType, setFlowWindowType] = React.useState<ReportApiFlowWindowType>(ReportApiFlowWindowType.MONTH)
	const [belowThresholdDays, setBelowThresholdDays] = React.useState(0)
	const [inputBelowThresholdDays, setInputBelowThresholdDays] = React.useState(0)
	const belowThresholdDaysMin = 1
	const belowThresholdDaysMax =
		flowWindowType === ReportApiFlowWindowType.DAY ? 1 : flowWindowType === ReportApiFlowWindowType.WEEK ? 7 : reportDate.daysInMonth?.valueOf() || 0
	const [tmvOrFixture, setTmvOrFixture] = React.useState<string>('TmvOnly=true')

	const reportQueryUrl = `${ReportApiType.FLOW.value}?FlowWindowType=${flowWindowType.value}&${
		props.usageContext === 'site' ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
	}&Date=${formatOutgoingDateTime({
		dateTime: reportDate,
		format: 'DateTimeObject',
	})}&DisplayDate=${formatOutgoingDateTime({
		dateTime: reportDate,
		format: 'DateTimeObject',
	})}&BelowThresholdDays=${belowThresholdDays}&${tmvOrFixture}`

	React.useEffect(() => {
		const getData = async () => {
			const [flowsReq] = await Promise.all([Request.get<FlowResult>(`report/${reportQueryUrl}`, context.appState.authState)])
			setFlow(flowsReq.data.flow)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, flowWindowType, reportDate, belowThresholdDays, tmvOrFixture])

	React.useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setBelowThresholdDays(inputBelowThresholdDays)
		}, 1000)

		return () => clearTimeout(delayDebounceFn)
	}, [inputBelowThresholdDays])

	const reportName = () => {
		let windowName = ''
		switch (flowWindowType) {
			case ReportApiFlowWindowType.DAY:
				windowName = 'Daily'
				break
			case ReportApiFlowWindowType.WEEK:
				windowName = 'Weekly'
				break
			case ReportApiFlowWindowType.MONTH:
				windowName = 'Monthly'
				break
		}

		return `${reportDate.toFormat(flowWindowType === ReportApiFlowWindowType.MONTH ? 'MM-yy' : 'yyyy-MM-dd')} ${windowName} Flow Report - ${
			props.usageContext === 'site' ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name
		}`
	}

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			<Row>
				<Col sm="auto" style={styles.select}>
					{props.reportTypeSelect}
				</Col>
				<Col sm="auto">
					{ReportApiFlowWindowTypeSelectOptions.map((windowType, index, array) => (
						<Button
							key={index}
							variant={windowType === flowWindowType ? 'primary' : 'secondary'}
							style={{ ...styles.button, ...(index === 0 ? styles.first : index === array.length - 1 ? styles.last : styles.middle) }}
							onClick={() => {
								if (windowType === ReportApiFlowWindowType.MONTH) setReportDate((current) => current.set({ day: 1 }))
								setFlow(undefined)
								setFlowWindowType(windowType)
							}}
						>
							{windowType.label}
						</Button>
					))}
				</Col>
				<Col sm="auto">
					<DatePicker
						wrapperClassName="datePicker"
						selected={reportDate.toJSDate()}
						onChange={(date) => {
							setPageStatus('Loading')
							setReportDate(
								DateTime.fromJSDate(date || new Date()).set({
									day: flowWindowType === ReportApiFlowWindowType.MONTH ? 1 : undefined,
									hour: 0,
									minute: 0,
									second: 0,
									millisecond: 0,
								})
							)
						}}
						isClearable={false}
						dateFormat={flowWindowType === ReportApiFlowWindowType.MONTH ? 'MMMM yyyy' : 'dd MMMM yyyy'}
						showMonthYearPicker={flowWindowType === ReportApiFlowWindowType.MONTH}
						showIcon
					/>
				</Col>
				<Col sm="auto">
					<Button
						variant={tmvOrFixture === 'TmvOnly=true' ? 'primary' : 'secondary'}
						style={{ ...styles.button, ...styles.first }}
						onClick={() => {
							setTmvOrFixture('TmvOnly=true')
						}}
					>
						Tmvs
					</Button>
					<Button
						variant={tmvOrFixture === 'FixtureOnly=true' ? 'primary' : 'secondary'}
						style={{ ...styles.button, ...styles.last }}
						onClick={() => {
							setTmvOrFixture('FixtureOnly=true')
						}}
					>
						Fixtures
					</Button>
				</Col>
				<Col sm="auto" className="pe-1">
					<Form.Check
						className="data-checkbox m-0"
						type="checkbox"
						checked={inputBelowThresholdDays !== 0}
						onChange={(e) => {
							if (e.target.checked) setInputBelowThresholdDays(1)
							else setInputBelowThresholdDays(0)
						}}
					/>
				</Col>
				<Col sm="auto" className="center-flex px-1">
					<span className={belowThresholdDays === 0 ? 'span-disabled' : undefined}>
						Display only {tmvOrFixture === 'TmvOnly=true' ? 'TMV' : 'Fixture'}s with{' '}
					</span>
				</Col>
				<Col sm="auto" className="px-0">
					<Form.Control
						disabled={inputBelowThresholdDays === 0}
						type="number"
						min={belowThresholdDaysMin}
						max={belowThresholdDaysMax}
						value={inputBelowThresholdDays}
						onChange={(e) => {
							if (Number(e.currentTarget.value) < belowThresholdDaysMin) setInputBelowThresholdDays(belowThresholdDaysMin)
							else if (Number(e.currentTarget.value) > belowThresholdDaysMax) setInputBelowThresholdDays(belowThresholdDaysMax)
							else setInputBelowThresholdDays(Number(e.currentTarget.value))
						}}
					/>
				</Col>
				<Col sm="auto" className="center-flex ps-1">
					<span className={belowThresholdDays === 0 ? 'span-disabled' : undefined}> or more consecutive days below threshold</span>
				</Col>
				<Col />
				<Col sm="auto">
					{pageStatus != 'Loading' && (
						<ExportDropdown
							pageStatus={pageStatus}
							setPageStatus={setPageStatus}
							setMessages={setMessages}
							pdfDownloads={{
								options: [
									{
										menuLabel: 'PDF',
										pdfUrl: reportQueryUrl,
										pdfFilename: reportName(),
									},
								],
							}}
							csvDownloads={{
								options: [
									{
										menuLabel: 'CSV',
										csvFilename: reportName(),
										data: (flow?.assetFlows || []).map((assetFlow) => {
											const flowObj = assetFlow as unknown as CsvData
											Array.from({
												length:
													flowWindowType === ReportApiFlowWindowType.MONTH
														? reportDate.daysInMonth || 30
														: flowWindowType === ReportApiFlowWindowType.WEEK
														? 7
														: 1,
											}).forEach((_, i) => {
												flowObj[`${reportDate.plus({ days: i }).toFormat('dd/MM')}`] = assetFlow.dailyFlows[i].durationSeconds
											})
											flowObj.totalRuntime = assetFlow.dailyFlows.reduce((sum, df) => (sum += df.durationSeconds), 0)
											return flowObj
										}),
										headers: getCsvFileHeaders(flowWindowType, reportDate),
									},
								],
							}}
						/>
					)}
				</Col>
			</Row>
			<Row>
				<Col>
					<Listing
						name="Flow"
						namePlural="Flows"
						list={flow?.assetFlows || []}
						getIDFunc={(af) => af.asset_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={(
							[
								{
									value: (item) => item.asset_Name,
									render: (item) => (
										<Link
											to={{
												pathname: `/asset/${item.asset_Id}`,
											}}
										>
											{item.asset_Name}
										</Link>
									),
									showHeader: true,
									headerText: 'Asset ID',
									sortColumnName: 'asset_Name',
									filterType: 'string',
									filterOptions: {
										columnName: 'asset_Name',
									},
								},
								{
									value: (item) => item.asset_Location || '',
									render: (item) => <>{item.asset_Location}</>,
									showHeader: true,
									headerText: 'Location',
									sortColumnName: 'asset_Location',
									filterType: 'string',
									filterOptions: {
										columnName: 'asset_Location',
									},
								},
								{
									value: (item) => item.daysBelowThreshold.toString().padStart(3, '0'),
									render: (item) => <>{item.daysBelowThreshold}</>,
									showHeader: true,
									headerText: 'Days Below Threshold',
									sortColumnName: 'daysBelowThreshold',
								},
							] as ListingColumn<AssetFlow>[]
						)
							.concat(
								flowWindowType === ReportApiFlowWindowType.WEEK
									? Array.from({ length: 7 }, (_, i) => ({
											value: (item) => item.dailyFlows[i].durationSeconds.toString().padStart(7, '0'),
											render: (item) => <>{secondsToReadable(item.dailyFlows[i].durationSeconds)}</>,
											showHeader: true,
											headerText: `${reportDate.plus({ days: i }).toFormat('dd/MM')}`,
											sortColumnName: `weekDay${i}`,
									  }))
									: flowWindowType === ReportApiFlowWindowType.MONTH
									? [
											{
												value: (item) => item.daysBelowThreshold.toString().padStart(3, '0'),
												render: (item) => (
													<>
														{pageStatus !== 'Loading' && (
															<div style={styles.calBox}>
																<CalendarHeatmap
																	startDate={reportDate.startOf('month').minus({ days: 1 }).toISODate() || ''}
																	endDate={reportDate.endOf('month').toISODate() || ''}
																	values={item.dailyFlows.map((df) => {
																		return {
																			date: df.date,
																			count: df.durationSeconds,
																		}
																	})}
																	horizontal={false}
																	showMonthLabels={false}
																	showWeekdayLabels={false}
																	classForValue={(value) => {
																		let color
																		if (value && flow && value.count >= flow.thresholdValueSeconds) {
																			color = 'cal-color-pass'
																		} else if (value && value.count != null) {
																			color = 'cal-color-fail'
																		} else if (DateTime.fromISO(value.date) >= DateTime.now().startOf('day')) {
																			color = 'cal-color-clear'
																		} else {
																			color = 'cal-color-no-data'
																		}
																		return color
																	}}
																	tooltipDataAttrs={(value: { date: string; count: number }) => {
																		return {
																			'data-tooltip-id': 'my-tooltip',
																			'data-tooltip-content': `${DateTime.fromISO(value.date).toFormat(
																				'dd/MM/yyyy'
																			)}: ${secondsToReadable(value.count)}`,
																		}
																	}}
																/>
															</div>
														)}
													</>
												),
												showHeader: true,
												headerText: 'Daily Usage',
											},
									  ]
									: []
							)
							.concat([
								{
									value: (item) =>
										item.dailyFlows
											.reduce((sum, df) => (sum += df.durationSeconds), 0)
											.toString()
											.padStart(7, '0'),
									render: (item) => (
										<>
											{secondsToReadable(
												item.dailyFlows.reduce((sum, df) => (sum += df.durationSeconds), 0),
												true
											)}
										</>
									),
									showHeader: true,
									headerText: 'Total Runtime',
									sortColumnName: `totalRuntime`,
								},
							])}
						defaultSort={{ column: 'alertCount', order: 'DSC' }}
					/>
				</Col>
			</Row>
			<Tooltip id="my-tooltip" />
		</>
	)
}

const styles = {
	select: { minWidth: '300px' },
	button: { minHeight: '38px' },
	first: {
		borderRadius: '4px 0 0 4px',
		marginRight: '-1px',
	},
	middle: {
		borderRadius: '0',
		marginRight: '-1px',
	},
	last: {
		borderRadius: '0 4px 4px 0',
	},
	calBox: { width: '150px' },
} satisfies Record<string, React.CSSProperties>

export default FlowListing
