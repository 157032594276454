import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FilterDropdownOption, Listing } from '../../components/UI/Listing/Listing'
import { SeverityIcon } from '../../components/UI/SeverityIcon/SeverityIcon'
import { UpdateStatusModal } from '../../components/UI/TaskListing/UpdateStatusModal'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { hasPermission } from '../../utilities/permissions/permission'

import { PageStatus } from '../../types/PageStatus'
import { TaskList, TaskListResult } from '../../models/Task'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'

import addIcon from '../../images/icons/add.svg'

interface TaskOverviewListingProps {
	filter?: string
	readOnly?: boolean
	canCreate?: boolean
}

const TaskOverviewListing = (props: TaskOverviewListingProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate()
	// Currently POSTPUTDELETE permissions are identical
	const hasEditPermissions = hasPermission(PermissionModelObject.Task, PermissionModelAction.POST, context.appState, PermissionModelContext.Site)

	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [showUpdateStatusModal, setShowUpdateStatusModal] = React.useState<boolean>(false)

	const [tasks, setTasks] = React.useState<TaskList[] | null>(null)
	const [tasksToUpdate, setTasksToUpdate] = React.useState<TaskList[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [taskResponse] = await Promise.all([
				Request.get<TaskListResult>(`task?${props.filter || `site_Id=${context.appState.currentSite?.site_Id}`}`, context.appState.authState),
			])
			setTasks(taskResponse.data.tasks)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const handleSelectedUpdateStatus = async (selected: string[]) => {
		const tasksToUpdate = tasks?.filter((task) => selected.includes(task.task_Id)) || []
		setTasksToUpdate(tasksToUpdate)
		setShowUpdateStatusModal(true)
	}

	const handleCompleteSelectedUpdateStatus = (updatedTasks: TaskList[]) => {
		const updatedTaskList =
			tasks?.map((oldTask) => {
				const updatedTask = updatedTasks.find((updatedTask) => updatedTask.task_Id === oldTask.task_Id)
				if (updatedTask) {
					return updatedTask
				} else {
					return oldTask
				}
			}) || []
		setTasks(updatedTaskList)
	}

	const priorityOptions = React.useMemo(
		() =>
			tasks?.reduce((final, curr) => {
				const priorityIndex = final.findIndex((prev) => prev.value === curr.severity_Id)
				if (priorityIndex < 0) {
					final.push({ value: curr.severity_Id, text: curr.severity_Name })
				}
				return final
			}, [] as FilterDropdownOption[]),
		[tasks]
	)

	const statusOptions = React.useMemo(
		() =>
			tasks?.reduce((final, curr) => {
				const statusIndex = final.findIndex((prev) => prev.value === curr.taskStatus_Id)
				if (statusIndex < 0) {
					final.push({ value: curr.taskStatus_Id, text: curr.taskStatus_Name })
				}
				return final
			}, [] as FilterDropdownOption[]),
		[tasks]
	)

	const userOptions = React.useMemo(
		() =>
			tasks
				?.flatMap((task) => task.task_TaskUsers)
				.reduce((final, curr) => {
					const userIndex = final.findIndex((prev) => prev.value === curr.user_Id)
					if (userIndex < 0 && curr.taskUser_User) {
						final.push({ value: curr.taskUser_User.user_Id, text: `${curr.taskUser_User.user_FirstName} ${curr.taskUser_User.user_LastName}` })
					}
					return final
				}, [] as FilterDropdownOption[]),
		[tasks]
	)

	const roleOptions = React.useMemo(
		() =>
			tasks
				?.flatMap((task) => task.task_TaskUsers)
				.reduce((final, curr) => {
					const userIndex = final.findIndex((prev) => prev.value === curr.userRole_Id)
					if (userIndex < 0 && curr.userRole_Id) {
						final.push({ value: curr.userRole_Id, text: curr.userRole_Name })
					}
					return final
				}, [] as FilterDropdownOption[]),
		[tasks]
	)

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			{tasksToUpdate.length > 0 && (
				<UpdateStatusModal
					show={showUpdateStatusModal}
					setShow={setShowUpdateStatusModal}
					tasks={tasksToUpdate}
					setMessages={setMessages}
					onSaveComplete={handleCompleteSelectedUpdateStatus}
				/>
			)}

			<Listing<TaskList>
				name="Task"
				namePlural="Tasks"
				list={tasks || []}
				getIDFunc={(item) => item.task_Id}
				selectedActions={!props.readOnly && hasEditPermissions ? [{ name: 'Update status of selected', method: handleSelectedUpdateStatus }] : []}
				headerButtons={
					!props.readOnly && props.canCreate && hasEditPermissions
						? [
								{
									content: (
										<>
											<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
											<span style={{ color: 'white' }}>Add New Task</span>
										</>
									),
									onClick: () => navigate('/task/new'),
								},
						  ]
						: []
				}
				columns={[
					{
						value: (item) => item.severity_Name,
						render: (item) => <SeverityIcon severityId={item.severity_Id} />,
						showHeader: true,
						headerText: 'Priority',
						sortColumnName: 'severity_Name',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'severity_Name',
							options: priorityOptions,
						},
					},
					{
						value: (item) => item.task_Name || '',
						render: (item) => (
							<Link
								to={{
									pathname: `/task/${item.task_Id}`,
								}}
							>
								{item.task_Name}
							</Link>
						),
						showHeader: true,
						headerText: 'Task Name',
						sortColumnName: 'task_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'task_Name',
						},
					},
					{
						value: (item) => item.task_Note,
						render: (item) => <>{item.task_Note}</>,
						showHeader: true,
						headerText: 'Description',
						sortColumnName: 'task_Note',
						filterType: 'string',
						filterOptions: {
							columnName: 'task_Note',
						},
					},
					{
						value: (item) =>
							item.task_TaskUsers
								.filter((tu) => tu.taskUser_User)
								.map((tu) => tu.taskUser_User.user_Id)
								.join(', '),
						render: (item) => (
							<>
								{item.task_TaskUsers
									.filter((tu) => tu.taskUser_User)
									.map((tu) => `${tu.taskUser_User.user_FirstName} ${tu.taskUser_User.user_LastName}`)
									.join(', ')}
							</>
						),
						showHeader: true,
						headerText: 'User',
						sortColumnName: 'user_UserName',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'user_UserId',
							options: userOptions,
						},
					},
					{
						value: (item) =>
							item.task_TaskUsers
								.filter((tu) => tu.userRole_Id)
								.map((tu) => tu.userRole_Id)
								.join(', '),
						render: (item) => (
							<>
								{item.task_TaskUsers
									.filter((tu) => tu.userRole_Id)
									.map((userRole) => userRole.userRole_Name)
									.join(', ')}
							</>
						),
						showHeader: true,
						headerText: 'Role',
						sortColumnName: 'userRole_Name',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'userRole_Id',
							options: roleOptions,
						},
					},
					{
						value: (item) => item.task_TaskAssets.map((taskAsset) => taskAsset.taskAsset_Asset.asset_Name).join(', '),
						render: (item) => (
							<>
								{item.task_TaskAssets.map((taskAsset, index) => (
									<Link
										key={taskAsset.asset_Id}
										to={{
											pathname: `/asset/${taskAsset.asset_Id}`,
										}}
									>
										{taskAsset.taskAsset_Asset.asset_Name}
										{index < item.task_TaskAssets.length - 1 ? ', ' : ''}
									</Link>
								))}
							</>
						),
						showHeader: true,
						headerText: 'Assets',
						sortColumnName: 'task_Assets',
						filterType: 'string',
						filterOptions: {
							columnName: 'task_Assets',
						},
					},
					{
						value: (item) => item.taskStatus_Name,
						render: (item) => <>{item.taskStatus_Name}</>,
						showHeader: true,
						headerText: 'Status',
						sortColumnName: 'taskStatus_Name',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'taskStatus_Name',
							options: statusOptions,
						},
					},
				]}
				defaultSort={{ column: 'task_Id', order: 'ASC' }}
				isLoading={pageStatus !== 'Ready'}
			/>
		</>
	)
}

export { TaskOverviewListing }
export type { TaskOverviewListingProps }
