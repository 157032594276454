import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Form, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Listing } from '../Listing/Listing'
import { MonthlyReading, MonthlyReadingResult } from '../../../models/Report'
import { Link } from 'react-router-dom'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { ReportApiType } from '../../../constants/report'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import ExportDropdown from './ExportDropdown'
import './Checkbox.css'
import { Messages, useMessageReducer } from '../Messages/Messages'

const csvFileHeaders = [
	{ key: 'asset_Name', columnName: 'TMV ID' },
	{ key: 'asset_SerialNo', columnName: 'Asset Number' },
	{ key: 'asset_Location', columnName: 'Location' },
	{ key: 'startTs', columnName: 'Start Time' },
	{ key: 'finishTs', columnName: 'End Time' },
	{ key: 'temperature', columnName: 'Temperature' },
]

interface MonthlyReadingListingProps {
	reportTypeSelect: JSX.Element
	usageContext: 'site' | 'siteGroup'
}

const MonthlyReadingListing = (props: MonthlyReadingListingProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [monthlyReadings, setMonthlyReadings] = React.useState<MonthlyReading[]>([])
	const [monthYear, setMonthYear] = React.useState(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
	const [excludeAssetsWithReadings, setExcludeAssetsWithReadings] = React.useState(false)

	const reportQueryUrl = `${ReportApiType.MONTHLY_READING.value}?${
		props.usageContext === 'site' ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
	}&Date=${formatOutgoingDateTime({
		dateTime: monthYear,
		format: 'DateTimeObject',
		timeZone: props.usageContext === 'site' ? context.appState.currentSite?.site_Timezone : context.appState.currentSiteGroup?.siteGroup_Timezone,
	})}&ExcludeAssetsWithReadings=${excludeAssetsWithReadings}`

	React.useEffect(() => {
		const getData = async () => {
			const [monthlyReadingsReq] = await Promise.all([Request.get<MonthlyReadingResult>(`report/${reportQueryUrl}`, context.appState.authState)])
			setMonthlyReadings(monthlyReadingsReq.data.monthlyReadings)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, monthYear, excludeAssetsWithReadings])

	const fileName = `${monthYear.toFormat('MM-yy')} Monthly Readings${excludeAssetsWithReadings ? ' Preparation ' : ' '}Report - ${
		props.usageContext === 'site' ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name
	}`

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			<Row>
				<Col sm="auto" style={styles.select}>
					{props.reportTypeSelect}
				</Col>
				<Col sm="auto">
					<DatePicker
						wrapperClassName="datePicker"
						selected={monthYear.toJSDate()}
						onChange={(date) =>
							setMonthYear(DateTime.fromJSDate(date || new Date()).set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
						}
						isClearable={false}
						dateFormat="MMMM yyyy"
						showMonthYearPicker
						showIcon
					/>
				</Col>
				<Col sm="auto">
					<Form.Check
						className="data-checkbox m-0"
						type="checkbox"
						label="Exclude TMVs with Readings"
						checked={excludeAssetsWithReadings}
						onChange={(e) => setExcludeAssetsWithReadings(e.target.checked)}
					/>
				</Col>
				<Col />
				<Col sm="auto">
					<ExportDropdown
						pageStatus={pageStatus}
						setPageStatus={setPageStatus}
						setMessages={setMessages}
						pdfDownloads={{
							options: [
								{
									menuLabel: 'PDF',
									pdfUrl: `${reportQueryUrl}&DisplayDate=${monthYear.toFormat('MMMM yyyy')}`,
									pdfFilename: fileName,
								},
							],
						}}
						csvDownloads={{
							options: [
								{
									menuLabel: 'CSV',
									csvFilename: fileName,
									data: monthlyReadings.map((reading) => ({
										...reading,
										startTs: reading.startTs
											? formatIncomingDateTime({
													dateTime: reading.startTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: reading.site_Timezone,
											  })
											: '',
										finishTs: reading.finishTs
											? formatIncomingDateTime({
													dateTime: reading.finishTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: reading.site_Timezone,
											  })
											: '',
									})),
									headers: csvFileHeaders,
								},
							],
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Listing
						name="Monthly Reading"
						namePlural="Monthly Readings"
						list={monthlyReadings}
						getIDFunc={(mr) => mr.asset_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={[
							{
								value: (item) => item.asset_Name,
								render: (item) => (
									<Link
										to={{
											pathname: `/asset/${item.asset_Id}`,
										}}
									>
										{item.asset_Name}
									</Link>
								),
								showHeader: true,
								headerText: 'TMV ID',
								sortColumnName: 'asset_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Name',
								},
							},
							{
								value: (item) => item.asset_SerialNo,
								render: (item) => <>{item.asset_SerialNo}</>,
								showHeader: true,
								headerText: 'Asset Number',
								sortColumnName: 'asset_SerialNo',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_SerialNo',
								},
							},
							{
								value: (item) => item.asset_Location || '',
								render: (item) => <>{item.asset_Location}</>,
								showHeader: true,
								headerText: 'Location',
								sortColumnName: 'asset_Location',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Location',
								},
							},
							{
								value: (item) => item.startTs || '',
								render: (item) => (
									<>
										{item.startTs
											? formatIncomingDateTime({
													dateTime: item.startTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: item.site_Timezone,
											  })
											: ''}
									</>
								),
								showHeader: true,
								headerText: 'Start Time',
								sortColumnName: 'startTs',
							},
							{
								value: (item) => item.finishTs || '',
								render: (item) => (
									<>
										{item.finishTs
											? formatIncomingDateTime({
													dateTime: item.finishTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: item.site_Timezone,
											  })
											: ''}
									</>
								),
								showHeader: true,
								headerText: 'End Time',
								sortColumnName: 'finishTs',
							},
							{
								value: (item) => item.temperature?.toString().padStart(4, '0') || 'No Reading',
								render: (item) => <>{item.temperature?.toString() || 'No Reading'}</>,
								showHeader: true,
								headerText: 'Temperature',
								sortColumnName: 'temperature',
							},
						]}
						defaultSort={{ column: 'asset_Name', order: 'ASC' }}
					/>
				</Col>
			</Row>
		</>
	)
}

const styles = {
	select: { minWidth: '300px' },
} satisfies Record<string, React.CSSProperties>

export default MonthlyReadingListing
