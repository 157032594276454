import * as msal from '@azure/msal-browser'

const apiEndpoint_Prod = 'ssf-prod-backend.azurewebsites.net/api'
const reportApiEndpoint_Prod = 'ssf-prod-report.azurewebsites.net/api'

const AAD_B2C_Name = 'enwaresupersmartflowprod'
// const B2C_signUpSignIn = 'B2C_1_signupSignin'
const B2C_signIn = 'B2C_1_signin'
const B2C_1_passwordReset = 'B2C_1_passwordReset'

const b2cPolicies = {
	names: {
		signIn: `${B2C_signIn}`,
		forgotPassword: `${B2C_1_passwordReset}`,
	},
	authorities: {
		signIn: {
			authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_signIn}`,
		},
		// signUpSignIn: {
		// 	authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_signUpSignIn}`,
		// },
		forgotPassword: {
			authority: `https://${AAD_B2C_Name}.b2clogin.com/${AAD_B2C_Name}.onmicrosoft.com/${B2C_1_passwordReset}`,
		},
	},
	authorityDomain: `${AAD_B2C_Name}.b2clogin.com`,
}

const msalConfig_Prod = {
	auth: {
		clientId: '8a4de76d-49b5-4820-8995-8462a90fbed6', // This is the ONLY mandatory field; everything else is optional.
		clientSecret: 'b.o8Q~g4UlzpbZIy~PEo2y9Jl7t.xUJo1Nd1zc6o',
		authority: b2cPolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
		knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
		//redirectUri: 'http://localhost:3000/home', // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
		redirectUri: 'https://ssf.smartflow.com.au/home', // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
	},
	scopes: ['https://enwaresupersmartflowprod.onmicrosoft.com/8a4de76d-49b5-4820-8995-8462a90fbed6/api'],
	system: {
		loggerOptions: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			loggerCallback: (level: any, message: any, containsPii: any) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case msal.LogLevel.Error:
						console.log('Error', message)
						return
					case msal.LogLevel.Info:
						console.log('Info', message)
						return
					case msal.LogLevel.Verbose:
						console.log('Verbose', message)
						return
					case msal.LogLevel.Warning:
						console.log('Warning', message)
						return
				}
			},
		},
	},
}

const msalInstance_Prod = new msal.PublicClientApplication(msalConfig_Prod)

export { msalInstance_Prod, msalConfig_Prod, apiEndpoint_Prod, reportApiEndpoint_Prod }
