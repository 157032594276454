import React from 'react'
import { AppContext } from '../../../App'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SiteGroupOverviewGraphs from '../SiteGroupDetails/SiteGroupOverviewGraphs'
import {
	FleetHealthReportData,
	TempExceptionHistoryAsset,
	TempExceptionHistoryResult,
	TemperatureException,
	TemperatureExceptionIdWithComment,
	TemperatureExceptionResult,
	TemperatureExceptionSummary,
	TemperatureExceptionSummaryIdWithComment,
} from '../../../models/Report'
import { ReportApiTempExceptionFilterType, ReportApiType } from '../../../constants/report'
import { Loading } from '../Loading/Loading'
import TemperatureExceptionEventsGraph from '../Trends/TemperatureExceptionEventsGraph'
import TemperatureExceptionReportListing from './TemperatureExceptionListing'
import TemperatureExceptionEventDetail from './TemperatureExceptionEventDetail'
import { ReportGenerationDataResult, defaultReportGenerationData } from '../../../models/ReportGenerationData'
import { base64ToArrayBuffer, downloadBlob, fetchPDFData } from '../../../utilities/download'
import { defaultCreated, defaultModified } from '../../../models/History'
import { recordStatus } from '../../../constants/recordStatus'

interface FleetHealthReportProps {
	context: 'site' | 'siteGroup'
}
type AddTempExceptionDetailEventToReportFunction = (tempException: TemperatureException) => void

const SectionTitle = (props: { title: string }) => {
	return (
		<Row className="mt-3">
			<Col>
				<span style={styles.sectionTitle}>{props.title}</span>
			</Col>
		</Row>
	)
}

const tempExceptionEventRow = (event: TemperatureException, timeZone?: string) => (
	<Row key={event.event_Id}>
		<Col sm={6}>
			<span>
				{event.startTs
					? formatIncomingDateTime({
							dateTime: event.startTs,
							format: 'DateAndTime',
							timeZone: timeZone,
					  })
					: ''}
			</span>
		</Col>

		<Col sm={6}>
			<span>
				{event.finishTs
					? formatIncomingDateTime({
							dateTime: event.finishTs,
							format: 'DateAndTime',
							timeZone: timeZone,
					  })
					: ''}
			</span>
		</Col>
	</Row>
)

const tempExTsSort = (a: TemperatureException, b: TemperatureException) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1)

const FleetHealthReport = (props: FleetHealthReportProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const isSiteContext = props.context === 'site'

	const [monthDate, setMonthDate] = React.useState<DateTime>(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
	const timeZone = isSiteContext ? context.appState.currentSite?.site_Timezone : context.appState.currentSiteGroup?.siteGroup_Timezone

	// The comment intersection type doesn't work here as updating the comment causes problematic re-renders
	const [temperatureExceptionSummaries, setTemperatureExceptionSummaries] = React.useState<TemperatureExceptionSummary[]>([])
	const [temperatureExceptionSummaryComments, setTemperatureExceptionSummaryComments] = React.useState<Record<string, string>>({})
	const [temperatureExceptionEventsDetailed, setTemperatureExceptionEventsDetailed] = React.useState<TemperatureException[]>([])
	const [temperatureExceptionEventsDetailedComments, setTemperatureExceptionEventsDetailedComments] = React.useState<Record<string, string>>({})

	const [tempExceptionHistoryAssets, setTempExceptionHistoryAssets] = React.useState<TempExceptionHistoryAsset[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [temperatureExceptionResult, tempExceptionHistoryResult] = await Promise.all([
				Request.get<TemperatureExceptionResult>(
					`report/${ReportApiType.TEMP_EXCEPTIONS.value}?TempExceptionFilterType=${ReportApiTempExceptionFilterType.MONTH.value}&${
						isSiteContext ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
					}&Date=${formatOutgoingDateTime({ dateTime: monthDate, format: 'DateTimeObject', timeZone: timeZone })}`,
					context.appState.authState
				),
				Request.get<TempExceptionHistoryResult>(
					`report/${ReportApiType.TEMP_EXCEPTION_HISTORY.value}?${
						isSiteContext ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
					}&Date=${formatOutgoingDateTime({
						dateTime: monthDate,
						format: 'DateTimeObject',
						timeZone: timeZone,
					})}&DisplayDate=${formatOutgoingDateTime({
						dateTime: monthDate,
						format: 'DateTimeObject',
					})}`,
					context.appState.authState
				),
			])

			setTemperatureExceptionSummaries(temperatureExceptionResult.data.temperatureExceptionSummaries)
			setTempExceptionHistoryAssets(tempExceptionHistoryResult.data.tempExceptionHistory.assets)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props, monthDate])

	const AddTempExceptionDetailEventToReport: AddTempExceptionDetailEventToReportFunction = (tempException: TemperatureException) => {
		setTemperatureExceptionEventsDetailed((prev) => [...prev, tempException])
	}

	const submitReport = async () => {
		setPageStatus('Submitting')
		const tempExceptionSummariesWithComments: TemperatureExceptionSummaryIdWithComment[] = Object.entries(temperatureExceptionSummaryComments).map(
			(obj) => ({
				asset_Id: obj[0],
				comment: obj[1],
			})
		)

		const allTempExceptionDetailsWithComments: TemperatureExceptionIdWithComment[] = temperatureExceptionEventsDetailed.map((detail) => ({
			event_Id: detail.event_Id,
			comment: temperatureExceptionEventsDetailedComments[detail.event_Id],
		}))

		let data: FleetHealthReportData | undefined

		if (props.context === 'site' && context.appState.currentSite) {
			data = {
				context: 'site',
				site_Id: context.appState.currentSite.site_Id,
				month: monthDate.month,
				year: monthDate.year,
				temperatureExceptionSummariesIdWithComment: tempExceptionSummariesWithComments,
				temperatureExceptionsDetailIdWithComment: allTempExceptionDetailsWithComments,
			}
		} else if (props.context === 'siteGroup' && context.appState.currentSiteGroup) {
			data = {
				context: 'siteGroup',
				siteGroup_Id: context.appState.currentSiteGroup.siteGroup_Id,
				month: monthDate.month,
				year: monthDate.year,
				temperatureExceptionSummariesIdWithComment: tempExceptionSummariesWithComments,
				temperatureExceptionsDetailIdWithComment: allTempExceptionDetailsWithComments,
			}
		}

		const reportGenerationData = defaultReportGenerationData({
			reportGenerationData_Data: window.btoa(JSON.stringify(data)),
			site_Id: isSiteContext ? context.appState.currentSite?.site_Id : undefined,
			siteGroup_Id: !isSiteContext ? context.appState.currentSiteGroup?.siteGroup_Id : undefined,
			created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
			modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
			recordStatus_Id: recordStatus.Active.id,
		})

		const reportGenerationDataRes = await Request.post<ReportGenerationDataResult>('reportGenerationData', reportGenerationData, context.appState.authState)

		const pdfData = await fetchPDFData(
			context.appState.authState,
			`${ReportApiType.FLEET_HEALTH.value}?Id=${reportGenerationDataRes.data.reportGenerationData[0].reportGenerationData_Id}`
		)

		if (pdfData)
			downloadBlob(
				base64ToArrayBuffer(pdfData),
				`${monthDate.toFormat('MM-yy')} Fleet Health Report - ${
					isSiteContext ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name
				}.pdf`,
				'application/pdf'
			)
		setPageStatus('Ready')
	}

	return (
		<>
			<Row>
				<Col sm="auto">
					<DatePicker
						wrapperClassName="datePicker"
						selected={monthDate.toJSDate()}
						onChange={(date) => {
							setMonthDate(DateTime.fromJSDate(date || new Date()).set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
							// Reset any added items from the previous month when changing the month
							setTemperatureExceptionEventsDetailed([])
							setTemperatureExceptionEventsDetailedComments({})
						}}
						isClearable={false}
						dateFormat="MMMM yyyy"
						showMonthYearPicker
						showIcon
					/>
				</Col>
				<Col />
				<Col sm="auto">
					<Button onClick={submitReport} disabled={pageStatus !== 'Ready'}>
						{pageStatus === 'Ready' ? 'Download' : <Loading show small />}
					</Button>
				</Col>
			</Row>
			<Card className="p-5">
				<Row>
					<Col className="p-3 center-flex">
						<span style={styles.title}>{`${monthDate.toFormat('MMMM yyyy')} Fleet Health Report`}</span>
					</Col>
				</Row>

				<SectionTitle title="Fleet Health Overview" />

				{React.useMemo(
					() => (
						<SiteGroupOverviewGraphs siteGroup={!isSiteContext} startDate={monthDate} flowCountConsecutiveDaysWithoutFlow />
					),
					[context, props, monthDate]
				)}

				<SectionTitle title="Temperature Exception Summary - High Grade" />
				<Row style={styles.fakeTableHeader}>
					<Col sm={2}>
						<span className="dashboard-list-titles">TMV ID</span>
					</Col>
					<Col sm={4}>
						<span className="dashboard-list-titles">LOCATION</span>
					</Col>
					<Col sm={1}>
						<span className="dashboard-list-titles">EXCEPTION COUNT</span>
					</Col>
					<Col sm={1}>
						<span className="dashboard-list-titles">
							LAST
							<br />
							MONTH
						</span>
					</Col>
					<Col sm={4}>
						<span className="dashboard-list-titles">ADD COMMENTS</span>
					</Col>
				</Row>
				{pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
					<Row>
						<Col>
							{temperatureExceptionSummaries
								.filter((summary) => summary.isHighGrade)
								.sort((a, b) => b.alertCount - a.alertCount)
								.map((summary) => (
									<Card key={summary.asset_Id} className="my-2">
										<Row>
											<Col sm={2}>
												<span>{summary.asset_Name}</span>
											</Col>
											<Col sm={4}>
												<span>{summary.asset_Location}</span>
											</Col>
											<Col sm={1}>
												<span>{summary.alertCount}</span>
											</Col>
											<Col sm={1}>
												<span>{summary.previousMonthCount}</span>
											</Col>
											<Col sm={4}>
												<Form.Control
													as="textarea"
													value={temperatureExceptionSummaryComments[summary.asset_Id]}
													onChange={(e) => {
														setTemperatureExceptionSummaryComments((prev) => {
															prev[summary.asset_Id] = e.target.value
															return { ...prev }
														})
													}}
													maxLength={256}
													style={{ resize: 'none' }}
												/>
											</Col>
										</Row>
									</Card>
								))}
							{temperatureExceptionSummaries.filter((summary) => summary.isHighGrade).length === 0 && (
								<Card className="my-2">
									<Row>
										<Col className="my-2 center-flex">
											<span>No Temperature Exceptions</span>
										</Col>
									</Row>
								</Card>
							)}
						</Col>
					</Row>
				) : (
					<Row>
						<Col className="center-flex">
							<Loading show />
						</Col>
					</Row>
				)}

				<SectionTitle title="Temperature Exception Events - Detailed View" />
				<TemperatureExceptionReportListing
					usageContext={props.context}
					hideDownload
					embeddedInFleetHealth={{
						temperatureExceptionSummaries: temperatureExceptionSummaries,
						addToFleetHealthReportFunction: AddTempExceptionDetailEventToReport,
						isTempExceptionEventInReportFunction: (event: TemperatureException) =>
							temperatureExceptionEventsDetailed.some((te) => te.event_Id === event.event_Id),
					}}
				/>
				{temperatureExceptionEventsDetailed.map((event) => (
					<Card key={event.event_Id} className="my-2 p-0">
						<Card.Header>
							<Row>
								<Col />
								<Col sm="auto">
									<Button
										onClick={() => {
											setTemperatureExceptionEventsDetailed(
												temperatureExceptionEventsDetailed.filter((e) => e.event_Id !== event.event_Id)
											)
											setTemperatureExceptionEventsDetailedComments((prev) => {
												prev[event.event_Id] = ''
												return { ...prev }
											})
										}}
									>
										X
									</Button>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body>
							<TemperatureExceptionEventDetail
								temperatureException={event}
								commentBox={
									<Form.Control
										as="textarea"
										value={temperatureExceptionEventsDetailedComments[event.event_Id]}
										onChange={(e) => {
											setTemperatureExceptionEventsDetailedComments((prev) => {
												prev[event.event_Id] = e.target.value
												return { ...prev }
											})
										}}
										maxLength={256}
										style={{ resize: 'none' }}
									/>
								}
							/>
						</Card.Body>
					</Card>
				))}
				{temperatureExceptionEventsDetailed.length === 0 && (
					<Card className="my-2">
						<Row>
							<Col className="center-flex">Select events above to add detailed views to the report</Col>
						</Row>
					</Card>
				)}

				<SectionTitle title="Temperature Exception Details" />
				<Row style={styles.fakeTableHeader}>
					<Col sm={1}>
						<span className="dashboard-list-titles">GRADE</span>
					</Col>
					<Col sm={2}>
						<span className="dashboard-list-titles">TMV ID</span>
					</Col>
					<Col sm={3}>
						<span className="dashboard-list-titles">LOCATION</span>
					</Col>
					<Col sm={1}>
						<span className="dashboard-list-titles">EXCEPTION COUNT</span>
					</Col>
					<Col sm={5}>
						<Row>
							<Col sm={6}>
								<span className="dashboard-list-titles">START TIME</span>
							</Col>
							<Col sm={6}>
								<span className="dashboard-list-titles">END TIME</span>
							</Col>
						</Row>
					</Col>
				</Row>
				{pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
					<Row>
						<Col>
							{temperatureExceptionSummaries
								.sort((a, b) => b.alertCount - a.alertCount)
								.map((summary) => (
									<Card key={summary.asset_Id} className="my-2">
										<Row>
											<Col sm={1}>
												<span>{summary.isHighGrade ? 'HIGH' : 'LOW'}</span>
											</Col>
											<Col sm={2}>
												<span>{summary.asset_Name}</span>
											</Col>
											<Col sm={3}>
												<span>{summary.asset_Location}</span>
											</Col>
											<Col sm={1}>
												<span>{summary.alertCount}</span>
											</Col>
											<Col sm={5}>
												{summary.temperatureExceptions.length > 20 ? (
													<>
														{summary.temperatureExceptions
															.sort(tempExTsSort)
															.slice(0, 9)
															.map((event) => tempExceptionEventRow(event, timeZone))}
														<Row>
															<Col className="center-flex">
																<span>{summary.temperatureExceptions.slice(10, -11).length} exceptions omitted</span>
															</Col>
														</Row>
														{summary.temperatureExceptions
															.sort(tempExTsSort)
															.slice(-10, -1)
															.map((event) => tempExceptionEventRow(event, timeZone))}
													</>
												) : (
													summary.temperatureExceptions.sort(tempExTsSort).map((event) => tempExceptionEventRow(event, timeZone))
												)}
											</Col>
										</Row>
									</Card>
								))}
							{temperatureExceptionSummaries.filter((summary) => summary.isHighGrade).length === 0 && (
								<Card className="my-2 ">
									<Row>
										<Col className="my-2 center-flex">
											<span>No Temperature Exceptions</span>
										</Col>
									</Row>
								</Card>
							)}
						</Col>
					</Row>
				) : (
					<Row>
						<Col className="center-flex">
							<Loading show />
						</Col>
					</Row>
				)}

				<SectionTitle title="Thermal Flush Events" />
				<Row style={styles.fakeTableHeader}>
					<Col sm={5}>
						<span className="dashboard-list-titles">TMV ID</span>
					</Col>
					<Col sm={6}>
						<span className="dashboard-list-titles">LOCATION</span>
					</Col>
					<Col sm={1}>
						<span className="dashboard-list-titles">THERMAL FLUSH COUNT</span>
					</Col>
				</Row>
				{pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
					<Row>
						<Col>
							{temperatureExceptionSummaries
								.filter((summary) => summary.thermalFlushCount > 0)
								.sort((a, b) => b.thermalFlushCount - a.thermalFlushCount)
								.map((summary) => (
									<Card key={summary.asset_Id} className="my-2">
										<Row>
											<Col sm={5}>
												<span>{summary.asset_Name}</span>
											</Col>
											<Col sm={6}>
												<span>{summary.asset_Location}</span>
											</Col>
											<Col sm={1}>
												<span>{summary.thermalFlushCount}</span>
											</Col>
										</Row>
									</Card>
								))}
							{temperatureExceptionSummaries.filter((summary) => summary.thermalFlushCount > 0).length === 0 && (
								<Card className="my-2 ">
									<Row>
										<Col className="my-2 center-flex">
											<span>No Thermal Flushes</span>
										</Col>
									</Row>
								</Card>
							)}
						</Col>
					</Row>
				) : (
					<Row>
						<Col className="center-flex">
							<Loading show />
						</Col>
					</Row>
				)}

				<SectionTitle title="Temperature Exception History" />
				<Row style={styles.fakeTableHeader}>
					<Col sm={2}>
						<span className="dashboard-list-titles">TMV ID</span>
					</Col>
					<Col sm={4}>
						<span className="dashboard-list-titles">LOCATION</span>
					</Col>
					{Array.from({ length: 6 }, (_, i) => (
						<Col key={i} sm={1}>
							<span className="dashboard-list-titles">{monthDate.minus({ months: 5 - i }).toFormat('MM/yyyy')}</span>
						</Col>
					))}
				</Row>
				{pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
					<Card className="my-2">
						{tempExceptionHistoryAssets
							.sort((a, b) => (b.monthlyAlertCounts.at(-1) || 0) - (a.monthlyAlertCounts.at(-1) || 0))
							.map((history, index, arr) => (
								<Row key={history.asset_Id} style={index + 1 !== arr.length ? styles.bottomBorder : undefined}>
									<Col sm={2}>
										<span>{history.asset_Name}</span>
									</Col>
									<Col sm={4}>
										<span>{history.asset_Location}</span>
									</Col>
									{Array.from({ length: 6 }, (_, i) => (
										<Col key={i} sm={1}>
											<span>{history.monthlyAlertCounts[i]}</span>
										</Col>
									))}
								</Row>
							))}
						{tempExceptionHistoryAssets.length === 0 && (
							<Row>
								<Col className="my-2 center-flex">
									<span>No Temperature Exception History</span>
								</Col>
							</Row>
						)}
					</Card>
				) : (
					<Row>
						<Col className="center-flex">
							<Loading show />
						</Col>
					</Row>
				)}

				<Row style={styles.fakeTableHeader}>
					<Col className="p-0">
						<TemperatureExceptionEventsGraph context={props.context} endMonthDate={monthDate} />
					</Col>
				</Row>
			</Card>
		</>
	)
}

const styles = {
	title: { fontFamily: 'Helvetica', fontSize: '32px', fontStyle: 'normal', fontWeight: 'normal', lineHeight: '32px' },
	sectionTitle: { fontFamily: 'Helvetica', fontSize: '26px', fontStyle: 'normal', fontWeight: '700', lineHeight: '26px' },
	fakeTableHeader: { paddingLeft: '10px', paddingRight: '10px', marginTop: '5px' },
	bottomBorder: { borderBottom: '1px solid var(--bs-card-border-color)', paddingBottom: '5px', marginBottom: '5px' },
} satisfies Record<string, React.CSSProperties>

export default FleetHealthReport
export { SectionTitle }
export type { AddTempExceptionDetailEventToReportFunction }
