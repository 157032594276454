import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import bcrypt from 'bcryptjs'
import { Formik, FormikHelpers } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import * as yup from 'yup'

import { Button } from '../Button/Button'
import { FormSelect, SelectOption } from '../Form/Select'
import { FormText } from '../Form/Text'
import { Loading } from '../Loading/Loading'
import { userRoleSiteDropdownValues, userRoleAdminDropdownValues } from '../../../constants/userRole'
import { defaultCreated, defaultModified } from '../../../models/History'
import { User, UserResult, defaultUser } from '../../../models/User'
import { UserSite, UserSiteResult, defaultUserSite } from '../../../models/UserSite'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'

interface AddEditUserModalProps {
	type: 'Site' | 'SiteGroup' | 'System'
	mode: 'Invite' | 'AddExisting' | 'Edit'
	siteOrGroupId: string | undefined | null

	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>

	/* Provided if we have a parent list of users to update */
	users?: User[]
	setUsers?: React.Dispatch<React.SetStateAction<User[] | null>>

	existingUserSites?: UserSite[]
	setExistingUserSites?: React.Dispatch<React.SetStateAction<UserSite[] | null>>

	editUserSite?: UserSite
}

interface FormUserSite extends UserSite {
	userSite_User: User & { user_OfflinePasswordConfirm: string | null }
}

const baseUserSchema = yup.object().shape({
	user_FirstName: yup.string().required('First name is required').max(100, 'First name must be less than ${max} characters'),
	user_LastName: yup.string().required('Last name is required').max(100, 'Last name must be less than ${max} characters'),
	user_Email: yup.string().required('Email is required').email('Email must be a valid email').max(100, 'Email must be less than ${max} characters'),
	user_OccupationDescription: yup.string().max(100, 'Description must be less than ${max} characters').nullable(),
})

const AddEditUserModal = (props: AddEditUserModalProps) => {
	const context = useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [userOptions, setUserOptions] = React.useState<User[]>([])

	const defaultFormUserSite: FormUserSite = props.editUserSite
		? { ...props.editUserSite, userSite_User: { ...props.editUserSite.userSite_User, user_OfflinePasswordConfirm: null } }
		: {
				...defaultUserSite({
					site_Id: props.type === 'Site' ? props.siteOrGroupId : null,
					siteGroup_Id: props.type === 'SiteGroup' ? props.siteOrGroupId : null,
					created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
				}),
				userSite_User: { ...defaultUser({}), user_OfflinePasswordConfirm: null },
		  }
	const [formUserSite, setFormUserSite] = React.useState<FormUserSite>(defaultFormUserSite)

	const userSiteValidationSchema = yup.object().shape({
		userSite_User:
			props.mode == 'Invite'
				? process.env.REACT_APP_IsLocalMode === 'true'
					? baseUserSchema.concat(
							yup.object().shape({
								user_OfflinePassword: yup.string().required('Password is required').max(1000, 'Password must be less than ${max} characters'),
								user_OfflinePasswordConfirm: yup
									.string()
									.required('Password Confirmation is required')
									.max(1000, 'Password must be less than ${max} characters')
									.test({
										name: 'match-password',
										message: 'Passwords must match.',
										test: (value, context) => value === context.parent.user_OfflinePassword,
									}),
							})
					  )
					: baseUserSchema
				: yup.object().shape({ user_Id: yup.string().required('User is required') }),
		userRole_Id: yup.string().required('User role is required'),
	})

	React.useEffect(() => {
		const getData = async () => {
			const [usersReq] = await Promise.all([Request.get<UserResult>('user', context.appState.authState)])
			setUserOptions(usersReq.data.users)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn && props.mode == 'AddExisting') {
			getData()
		} else {
			setPageStatus('Ready')
		}
	}, [context, props.mode])

	const handleAddEditUserSubmit = async (values: FormUserSite, formikHelpers: FormikHelpers<FormUserSite>) => {
		// System users do not get a UserSite table entry, the role is just added to the user
		if (props.type === 'System') {
			values.userSite_User.userRole_Id = values.userRole_Id
		}

		// Handle User Creation / Update
		if (props.mode === 'Invite') {
			// If this is a local build, directly create the user in the DB
			if (process.env.REACT_APP_IsLocalMode === 'true') {
				// Salt and Has the password before storing in DB
				const salt = bcrypt.genSaltSync(10)
				const hash = bcrypt.hashSync(values.userSite_User.user_OfflinePassword || '', salt)

				values.userSite_User.user_OfflinePassword = hash
				values.userSite_User.user_OfflinePasswordConfirm = null

				const userRes = await Request.post<UserResult>('user', values.userSite_User, context.appState.authState)
				values.userSite_User = { ...values.userSite_User, ...userRes.data.users[0] }
			} else {
				// This is not a local build, so an Azure record must be created, Invite user
				const userInviteRes = await Request.post<UserResult>('userInvite', values.userSite_User, context.appState.authState)
				values.userSite_User = { ...values.userSite_User, ...userInviteRes.data.users[0] }
			}
		} else if (props.mode === 'Edit') {
			// Update existing User
			await Request.put<UserResult>('user', values.userSite_User, context.appState.authState)
		}

		// Handle User Role Creation / Update
		if (props.type !== 'System') {
			if (props.mode === 'Edit') {
				// Update an existing userSite record
				return Request.handleRequest(() => Request.put<UserSiteResult>(`userSite`, values, context.appState.authState), {
					successFunction: (data) => {
						if (data.userSites.length > 0 && props.setExistingUserSites && props.existingUserSites) {
							props.setExistingUserSites([...props.existingUserSites.filter((us) => us.userSite_Id !== values.userSite_Id), ...data.userSites])
							props.setShow(false)
						}
					},
					messageAction: 'creating',
					messageObject: 'site',
				})
			} else if (props.mode === 'AddExisting' || props.mode === 'Invite') {
				// Create a new userSite record
				return Request.handleRequest(
					() =>
						Request.post<UserSiteResult>(`userSite`, { ...values, user_Id: values.userSite_User.user_Id } as UserSite, context.appState.authState),
					{
						successFunction: (data) => {
							if (data.userSites.length > 0 && props.setExistingUserSites && props.existingUserSites) {
								props.setExistingUserSites([...props.existingUserSites, ...data.userSites])
								props.setShow(false)
							}
						},
						messageAction: 'creating',
						messageObject: 'site',
					}
				)
			}
		}

		// If a parent list of users has been provided, update it
		if (props.setUsers && props.users) {
			if (props.mode === 'Invite') {
				props.setUsers(props.users.concat(values.userSite_User))
				props.setShow(false)
			} else {
				const updatedUserIndex = props.users.findIndex((u) => u.user_Id === values.userSite_User.user_Id)
				if (updatedUserIndex > -1) {
					// Update the user in the list
					const updateUsers = [...props.users]
					updateUsers[updatedUserIndex] = values.userSite_User
					props.setUsers(updateUsers)
					props.setShow(false)
				}
			}
		}

		props.setShow(false)
		formikHelpers.resetForm()
	}

	return (
		<Formik initialValues={formUserSite} validationSchema={userSiteValidationSchema} onSubmit={handleAddEditUserSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, setFieldValue, handleReset }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShow(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>{`${props.mode === 'Edit' ? 'Edit' : `Add ${props.mode === 'Invite' ? 'New' : 'Existing'}`} User`}</Modal.Title>
					</Modal.Header>
					{pageStatus === 'Ready' ? (
						<>
							<Modal.Body>
								{props.mode === 'Invite' || props.mode === 'Edit' ? (
									<>
										<FormText
											name="userSite_User.user_FirstName"
											value={values.userSite_User.user_FirstName}
											onChange={handleChange}
											label="First Name"
											required
											feedback={
												touched.userSite_User &&
												touched.userSite_User.user_FirstName &&
												errors.userSite_User &&
												errors.userSite_User.user_FirstName
													? errors.userSite_User.user_FirstName
													: ''
											}
											isInvalid={
												touched.userSite_User &&
												touched.userSite_User.user_FirstName &&
												errors.userSite_User &&
												!!errors.userSite_User.user_FirstName
											}
										/>
										<FormText
											name="userSite_User.user_LastName"
											value={values.userSite_User.user_LastName}
											onChange={handleChange}
											label="Last Name"
											required
											feedback={
												touched.userSite_User &&
												touched.userSite_User.user_LastName &&
												errors.userSite_User &&
												errors.userSite_User.user_LastName
													? errors.userSite_User.user_LastName
													: ''
											}
											isInvalid={
												touched.userSite_User &&
												touched.userSite_User.user_LastName &&
												errors.userSite_User &&
												!!errors.userSite_User.user_LastName
											}
										/>
										<FormText
											name="userSite_User.user_Email"
											value={values.userSite_User.user_Email}
											onChange={handleChange}
											label="Email"
											required
											disabled={!!props.editUserSite}
											feedback={
												touched.userSite_User &&
												touched.userSite_User.user_Email &&
												errors.userSite_User &&
												errors.userSite_User.user_Email
													? errors.userSite_User.user_Email
													: ''
											}
											isInvalid={
												touched.userSite_User &&
												touched.userSite_User.user_Email &&
												errors.userSite_User &&
												!!errors.userSite_User.user_Email
											}
										/>
										{process.env.REACT_APP_IsLocalMode === 'true' && props.mode === 'Invite' && (
											<>
												<FormText
													name="userSite_User.user_OfflinePassword"
													value={values.userSite_User.user_OfflinePassword || ''}
													onChange={handleChange}
													label="Password"
													type="password"
													required
													feedback={
														touched.userSite_User &&
														touched.userSite_User.user_OfflinePassword &&
														errors.userSite_User &&
														errors.userSite_User.user_OfflinePassword
															? errors.userSite_User.user_OfflinePassword
															: ''
													}
													isInvalid={
														touched.userSite_User &&
														touched.userSite_User.user_OfflinePassword &&
														errors.userSite_User &&
														!!errors.userSite_User.user_OfflinePassword
													}
												/>
												<FormText
													name="userSite_User.user_OfflinePasswordConfirm"
													value={values.userSite_User.user_OfflinePasswordConfirm || ''}
													onChange={handleChange}
													label="Confirm Password"
													type="password"
													required
													feedback={
														touched.userSite_User &&
														touched.userSite_User.user_OfflinePasswordConfirm &&
														errors.userSite_User &&
														errors.userSite_User.user_OfflinePasswordConfirm
															? errors.userSite_User.user_OfflinePasswordConfirm
															: ''
													}
													isInvalid={
														touched.userSite_User &&
														touched.userSite_User.user_OfflinePasswordConfirm &&
														errors.userSite_User &&
														!!errors.userSite_User.user_OfflinePasswordConfirm
													}
												/>
											</>
										)}
										<FormText
											name="userSite_User.user_OccupationDescription"
											value={values.userSite_User.user_OccupationDescription || ''}
											label="Occupational Role/Job Description"
											onChange={handleChange}
											feedback={
												touched.userSite_User &&
												touched.userSite_User.user_OccupationDescription &&
												errors.userSite_User &&
												errors.userSite_User.user_OccupationDescription
													? errors.userSite_User.user_OccupationDescription
													: ''
											}
											isInvalid={
												touched.userSite_User &&
												touched.userSite_User.user_OccupationDescription &&
												errors.userSite_User &&
												!!errors.userSite_User.user_OccupationDescription
											}
										/>
									</>
								) : props.existingUserSites ? (
									<FormSelect
										name="userSite_User.user_Id"
										required
										options={userOptions
											.filter((u) => !props.existingUserSites?.some((us) => us.userSite_User.user_Id === u.user_Id))
											.map((u) => ({ value: u.user_Id, label: u.user_Email }))
											.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
										value={values.userSite_User.user_Id || ''}
										onChange={(option) => {
											setFieldValue('userSite_User.user_Id', (option as SelectOption<string>).value)
										}}
										label="Existing Users"
										feedback={
											touched.userSite_User && touched.userSite_User.user_Id && errors.userSite_User && errors.userSite_User.user_Id
												? errors.userSite_User.user_Id
												: ''
										}
									/>
								) : null}

								{((props.type === 'System' && (props.editUserSite?.userSite_User.userRole_Id || props.mode === 'Invite')) ||
									props.type === 'Site' ||
									props.type === 'SiteGroup') && (
									<FormSelect
										name="userRole_Id"
										required
										options={props.type === 'System' ? userRoleAdminDropdownValues : userRoleSiteDropdownValues}
										value={values.userRole_Id || ''}
										onChange={(option) => {
											setFieldValue('userRole_Id', (option as SelectOption<string>).value)
										}}
										label="User Role"
										feedback={touched.userRole_Id ? errors.userRole_Id : ''}
									/>
								)}
							</Modal.Body>

							<Modal.Footer>
								<Button
									disabled={isSubmitting}
									onClick={() => {
										handleReset()
										setFormUserSite(defaultFormUserSite)
										props.setShow(false)
									}}
								>
									Cancel
								</Button>
								<Button
									disabled={isSubmitting}
									onClick={() => {
										handleSubmit()
										setFormUserSite(defaultFormUserSite)
									}}
								>
									Save
								</Button>
							</Modal.Footer>
						</>
					) : (
						<Row>
							<Col className="d-flex align-items-center justify-content-center p-5">
								<Loading show />
							</Col>
						</Row>
					)}
				</Modal>
			)}
		</Formik>
	)
}

export { AddEditUserModal }
