import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { siteStatus, SiteStatus, SiteStatusId } from '../constants/siteStatus'

interface Site {
	site_Id: string
	site_Name: string
	site_Version: string | null
	site_IsAliveTs: string | null
	detailedStatuses: SiteDetailedStatus[]
	site_Timezone: string
	site_LastExportFileName: string | null

	siteGroup_Id: string | null
	siteGroup_Name: string | null
	siteGroup_ParentId: string | null
	siteStatus_Id: SiteStatusId | null
	siteStatus_Name: SiteStatus | null

	asset_Count: number

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface SiteResult extends Validate.Result {
	sites: Site[]
}

const defaultSite = (site: Partial<Site>): Site => ({
	site_Id: site.site_Id || '',
	site_Name: site.site_Name || '',
	site_Version: site.site_Version || null,
	site_IsAliveTs: site.site_IsAliveTs || null,
	detailedStatuses: site.detailedStatuses || [],
	site_Timezone: site.site_Timezone || 'Australia/Sydney',
	site_LastExportFileName: site.site_LastExportFileName || null,

	siteGroup_Id: site.siteGroup_Id || null,
	siteGroup_Name: site.siteGroup_Name || null,
	siteGroup_ParentId: site.siteGroup_ParentId || null,
	siteStatus_Id: siteStatus.Live.id,
	siteStatus_Name: 'Live',

	asset_Count: site.asset_Count || 0,

	created: site.created || defaultCreated({}),
	modified: site.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

interface SiteSystemStatus {
	site_Id: string
	site_IsAliveTs: string | null
	detailedStatuses: SiteDetailedStatus[]
}

interface SiteDetailedStatus {
	statusType: string | null
	percentage: number | null
}

interface SiteSystemStatusResult extends Validate.Result {
	sites: SiteSystemStatus[]
}

const defaultSiteSystemStatus = (siteSystemStatus: Partial<SiteSystemStatus>): SiteSystemStatus => ({
	site_Id: siteSystemStatus.site_Id || '',
	site_IsAliveTs: siteSystemStatus.site_IsAliveTs || null,
	detailedStatuses: siteSystemStatus.detailedStatuses || [],
})

export { defaultSite, defaultSiteSystemStatus }
export type { Site, SiteResult, SiteSystemStatus, SiteSystemStatusResult, SiteDetailedStatus }
